import React from "react";
import styles from "./style.module.scss";
import LightWrapper from "../../../../components/wrapper/lightWrapper";
import MobileImg from "../../../../assets/mobile.png";
import PhotoOne from "../../../../assets/merch.png"
import { Link } from "react-router-dom";
import Gplay from "../../../../assets/gplay.png";
import Apple from "../../../../assets/apple.png";

const Products = () => {
  return (
    <div className={`containerWidth ${styles.productContainer}`} id="products">
      <div className={styles.productInner}>
        <h2>Our Products</h2>
        <p>
          SpendTheBits offers two flagship products, a mobile app, and a
          merchant portal, designed to meet your diverse needs.
        </p>
        <div className={styles.productBlock}>
          <LightWrapper>
            <div>
              <h4>For Customers</h4>
              <h3>Mobile App</h3>
              <p>
                Our mobile app allows you to send and receive cryptocurrency
                quickly and securely, using the power of the XRP Ledger. With
                SpendTheBits, you can store your cryptocurrency safely and
                easily, without worrying about security risks or technical
                challenges. Our app also provides real-time transaction
                monitoring, transaction history, and other analytical tools to
                help you manage your digital assets effectively.
              </p>
              {/* <a href="https://spendthebits.com" className="defaultBtn">
                Learn More
              </a> */}
              <div className={styles.appDownload}>
                <a href="https://play.google.com/store/apps/details?id=com.bitcon&hl=en_US">
                  <img src={Gplay} alt="Google Play"/>
                </a>
                <a href="https://apps.apple.com/ca/app/spend-the-bits/id1528045267">
                  <img src={Apple} alt="Apple Store"/>
                </a>
              </div>
            </div>
            <img src={MobileImg} alt="Mobile App" />
          </LightWrapper>
          <LightWrapper className={styles.merchantAppBlock}>
            <div>
              <h4>For Merchants</h4>
              <h3>Merchant Portal</h3>
              <p>
                Our merchant portal provides a seamless and secure payment
                experience for merchants, powered by the XRP Ledger. With
                portal, merchants can easily integrate the payment system into
                their checkout process and accept payments in multiple
                cryptocurrencies, catering to a broader customer base. The
                portal also offers fast transaction settlements, low transaction
                fees, and access to real-time transaction monitoring and other
                analytical tools to help merchants gain insights for business
                operations.
              </p>
              <Link to={'/feature'} className="defaultBtn">Learn More</Link>
            </div>
            <img src={PhotoOne} alt="Mobile App" />
          </LightWrapper>
        </div>
      </div>
    </div>
  );
};

export default Products;
