import React from "react";
import styles from "./style.module.scss";
import { SolidWrapper } from "../../../../components";
// import AboutImg from "../../../../assets/about.png";
import FeatureOneImg from "../../../../assets/lowTrans.png";
import FeatureTwoImg from "../../../../assets/secure.png";
// import FeatureThreeImg from "../../assets/fast.png";

const About = () => {
  return (
    <div className={`containerWidth ${styles.aboutContainer}`} id="about">
      <div className={styles.aboutInner}>
        <SolidWrapper>
          <div>
            <h2>
              About SpendTheBits
            </h2>
            <p>
              Welcome to SpendTheBits, the ultimate payment solution for your
              digital assets. We are committed to providing a fast and secure
              transaction platform based on the XRP Ledger, enabling you to
              manage your cryptocurrency with ease and confidence. Our mission
              is to create an innovative payment solution that is easy to use,
              affordable, and reliable, offering you a seamless experience that
              you can enjoy every day.
            </p>
          </div>
          {/* <div>
            <img src={AboutImg} alt="About" />
          </div> */}
        </SolidWrapper>
      </div>
      <div className={styles.featuresInner}>
        <h2>Features</h2>
        <p>
          With SpendTheBits, you can store your digital assets safely and
          easily, without worrying about security risks or technical challenges.
        </p>
        <div className={styles.featureCards}>
          <SolidWrapper>
            <div>
              <img src={FeatureOneImg} alt="Feature" />
            </div>
            <div>
              <h3>Low Transaction Fees</h3>
              <p>
                SpendTheBits offers low transaction fees, allowing you to save
                money on every transaction you make.
              </p>
            </div>
          </SolidWrapper>
          <SolidWrapper>
            <div>
              <img src={FeatureTwoImg} alt="Feature" />
            </div>
            <div>
              <h3>Secure and Reliable</h3>
              <p>
                SpendTheBits provides a secure and reliable payment solution
                powered by the XRP Ledger and robust security features.
              </p>
            </div>
          </SolidWrapper>
          <SolidWrapper>
            <div>
              <img src={FeatureOneImg} alt="Feature" />
            </div>
            <div>
              <h3>Fast and Efficient</h3>
              <p>
                SpendTheBits offers fast and efficient transactions with instant
                settlement and real-time transaction monitoring.
              </p>
            </div>
          </SolidWrapper>
        </div>
        {/* <div className={styles.seeAllFeatures}>
            <a href="https://spendthebits.com">See all features</a>
        </div> */}
      </div>
    </div>
  );
};

export default About;
