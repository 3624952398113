import React from "react";
import styles from "./style.module.scss";

const BurgerMenuIcon = (props) => {
  return (
    <label className={styles.burger} htmlFor="burger">
      <input type="checkbox" id="burger" onChange={props.onChange} checked={props.displayMenu}/>
      <span></span>
      <span></span>
      <span></span>
    </label>
  );
};

export default BurgerMenuIcon;
