import React from "react";
import styles from "./style.module.scss";
import { SolidWrapper } from "../../../../components";
import CommitImg from "../../../../assets/commit.png";

const Commitment = () => {
  return (
    <div className={`containerWidth ${styles.commitContainer}`}>
      <div className={styles.commitInner}>
        <SolidWrapper>
          <h2>Our Commitment</h2>
          <p>
            At SpendTheBits, we are committed to providing you with a
            one-stop-shop for all your crypto-related needs. We believe in the
            power of blockchain technology to revolutionize the way we make
            payments, and we are dedicated to delivering a seamless and secure
            experience that you can trust. Our goal is to create a payment
            solution that is easy to use, affordable, and innovative, offering
            you the ultimate payment experience.
          </p>
          <img src={CommitImg} alt="Commitment" />
        </SolidWrapper>
      </div>
    </div>
  );
};

export default Commitment;
