import React from 'react';
import { FaqBox } from '../../components';
import { faqData } from '../../utils/faq';
import styles from "./style.module.scss";

const Faq = () => {
  return (
    <>
    <div className={styles.faqPageHeader}>
      <h2>FAQs</h2>
    </div>
      <div className={`containerWidth ${styles.faqContainer}`}>
        <div className={styles.faqInner}>
            {
              faqData.map((data, i) => {
                return <FaqBox question={data.question} answer={data.answer} key={i}/>
              })
            }
        </div>
    </div>
    </>
  )
}

export default Faq;