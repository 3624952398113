import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./style.module.scss";
import bannerImg from "../../../../assets/photoThree.png";

const Banner = () => {
  return (
    <div className={classes.bannerContainer}>
      <Container>
        {/* <Row className={classes.logoBlock}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={logoWhite} alt="Spectrum" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}></Col>
        </Row> */}
        <Row className={classes.bannerTextBlock}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h1>
              The ultimate crypto payment platform and crypto payment processor.
            </h1>
            <h4>Accept crypto payments for your business</h4>
            <p>
              SpendTheBits was developed to provide users with the ability to
              pay using crypto online and in-store. It utilizes the XRP Ledger
              to provide an industry-leading cryptocurrency payment processor —
              designed to eliminate high fees.
            </p>
            <div className={classes.bookBtn}>
              <a href="tel:+17809529003">Call us</a>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.bannerImgContainer}
          >
            <img src={bannerImg} alt="Banner" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
