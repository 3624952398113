import styles from "./style.module.scss";

const CashPrivacyPolicy = () => {
  return (
    <div className={`containerWidth ${styles.privacyPolicy}`}>
      <div>
        <h1>Privacy Policy</h1>
        <p>Last modified: 19 MARCH, 2024</p>
        <h4>INTRODUCTION</h4>
        <p>
          SpendTheBits Inc. (the "Company" or "We") respects your privacy and is
          committed to protecting it by complying with this Privacy Policy.
        </p>
        <p>This Privacy Policy describes:</p>
        <ul>
          <li>
            The types of information we may collect or that you may provide when
            you purchase, download, install, register with, access, or use the
            SpendTheBits Mobile App (the "App"), including using our new
            product, STB CASH.
          </li>
          <li>
            Our practices for collecting, using, maintaining, protecting, and
            disclosing that information
          </li>
        </ul>
        <p>
          With the introduction of STB CASH, a versatile Canadian fiat payment
          platform designed to streamline how Canadians transact daily, our
          commitment to your privacy extends to all interactions and
          transactions made through this new feature. STB CASH merges the
          convenience of digital payments with the robustness of traditional
          banking, minus the monthly fees, and integrates with Interac for
          effortless deposits and withdrawals, including options for EFT and
          wire transactions.
        </p>
        <p>
          We will only use your Personal Information in accordance with this
          Privacy Policy unless otherwise required by applicable law. We take
          steps to ensure that the Personal Information that we collect about
          you is adequate, relevant, not excessive, and used for limited
          purposes.
        </p>
        <p>
          Privacy laws in Canada generally define "Personal Information" as any
          information about an identifiable individual, which includes
          information that can be used on its own or with other information to
          identify, contact, or locate a single person (“Personal Information”).
          Personal Information does not include business contact information,
          including your name, title, or business contact information.
        </p>
        <p>
          This Privacy Policy applies only to information we collect in this App
          and/or in email, text, and other electronic communications sent
          through or in connection with this App.
        </p>
        <p>This Privacy Policy DOES NOT apply to information that:</p>
        <ul>
          <li>
            we collect offline or on any other Company apps or websites,
            including websites you may access through this App; or
          </li>
          <li>
            you provide to or is collected by any third party (see Third-Party
            Information Collection).
          </li>
        </ul>
        <p>
          We encourage you to read the privacy policies of any other websites or
          apps you visit or use, including those that may be linked to or from
          the SpendTheBits App or STB CASH, to understand their information
          practices.
        </p>
        <p>
          Please read this Privacy Policy carefully to understand our policies
          and practices for collecting, processing, and storing your
          information. If you do not agree with our policies and practices, do
          not download, register with, or use this App or engage with STB CASH.
          By downloading, registering with, or using this App, and engaging in
          transactions through STB CASH, you indicate that you understand,
          accept, and consent to the practices described in this Privacy Policy.
          This policy may change from time to time (see Changes to Our Privacy
          Policy). Your continued use of this App and STB CASH, after we make
          changes, indicates that you accept and consent to those changes, so
          please check the Privacy Policy periodically for updates.
        </p>
        <h4>INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT</h4>
        <p>
          We collect information about you through various means to provide and
          improve our services, including the SpendTheBits Mobile App and STB
          CASH. Our methods include:
        </p>
        <ul>
          <li>
            Direct interactions with you when you provide information to us,
            such as by filling in forms within the App, registering for STB
            CASH, or corresponding with us directly.
          </li>
          <li>
            Automated technologies or interactions when you use the App or STB
            CASH, such as usage details, IP addresses, and information collected
            through cookies, web beacons, and other tracking technologies.
          </li>
          <li>
            [OTHER COLLECTION METHODS], which might include surveys, user
            feedback, or publicly available information.
          </li>
        </ul>
        <p>The types of information we collect include:</p>
        <p>
          - Personal Information that can be used to directly or indirectly
          identify you. This encompasses details like your name, mailing
          address, email address, telephone number, IP address, usernames,
          billing and account information, and any transactions made through STB
          CASH Platform or SpendTheBits app, including personal payments and
          business transactions. We provide an opportunity for any user to
          unsubscribe from SpendTheBits or opt-out of contact for marketing
          purposes on an ongoing basis by e-mailing
          privacyofficer@spendthebits.com.
        </p>
        <ul>
          <li>
            <b>Non-Personal Information</b> that does not directly or indirectly
            reveal your identity or directly relate to an identified individual,
            such as demographic information, or statistical or aggregated
            information. We may derive non-personal statistical or aggregated
            data from Personal Information. For example, we may aggregate
            Personal Information to calculate the percentage of users accessing
            a specific app feature.
          </li>
          <li>
            Technical Information regarding your device and usage, including
            login data, operating system, browser type, time zone setting, and
            device type.
          </li>
          <li>
            Blockchain Activity Monitoring for identifying potential fraudulent
            or illegal activities, including money laundering or terrorist
            financing, by analyzing bitcoin blockchain transactions.
          </li>
          <li>
            <b>Activity on the Cash App Platform</b>, in an effort to identify
            any activity on dark markets or any fraudulent activity or illegal
            activity, including money laundering or terrorist financing.
          </li>
        </ul>
        <p>
          <b>Information You Provide to Us</b>
        </p>
        <p>
          When you download, register with, or use this SpendTheBits including
          STB Cash App, we may ask you to provide:
        </p>
        <ul>
          <li>
            information by filling in forms in the App, which includes
            information you provide when registering to use the App;
          </li>
          <li>
            records and copies of your correspondence, including email addresses
            and phone numbers, if you contact us; and
          </li>
          <li>
            details of transactions you carry out through the App and we may
            require you to provide financial information before placing an order
            through the App.
          </li>
        </ul>
        <p>
          <b>Automatic Information Collection and Tracking Technologies</b>
        </p>
        <p>
          When you download, access, and use the App, it may automatically
          collect:
        </p>
        <ul>
          <li>
            <b>Usage details.</b> When you access and use the App, we may
            automatically collect certain details of your access to and use of
            the App, including traffic data, location data, and other
            communication data and the resources that you access and use on or
            through the App.
          </li>
          <li>
            <b>Device information.</b> We may collect information about your
            mobile device and internet connection, including the device's unique
            device identifier, IP address, operating system, browser type,
            mobile network information, and the device's telephone number.
          </li>
          <li>
            <b>Stored information and files.</b> The App also may access
            metadata and other information associated with other files stored on
            your device. This may include, for example, photographs, audio and
            video clips, personal contacts, and address book information.
          </li>
          <li>
            <b>Location information.</b> This App collects real-time information
            about the location of your device. The technologies we use for this
            automatic data collection may include:
          </li>
          <li>
            <b>Cookies (or mobile cookies).</b> A cookie is a small file placed
            on your smartphone. It may be possible to refuse to accept mobile
            cookies by activating the appropriate setting on your smartphone.
            However, if you select this setting you may be unable to access
            certain parts of our App.
          </li>
          <li>
            <b>Web Beacons.</b> Pages of the App and our emails may contain
            small electronic files known as web beacons (also referred to as
            clear gifs, pixel tags, and single-pixel gifs) that permit the
            Company, for example, to count users who have visited those pages
            and for other related app statistics (for example, recording the
            popularity of certain app content and verifying system and server
            integrity).
          </li>
        </ul>
        <p>
          The information we collect automatically is statistical information
          and may include Personal Information. We may maintain it or associate
          it with Personal Information we collect in other ways or receive from
          third parties. This usage information helps us to improve our App and
          to deliver a more personalized service, including by helping us to:
        </p>
        <ul>
          <li>estimate our audience size and usage patterns;</li>
          <li>
            store information about your preferences and customize our App
            according to your individual interests;
          </li>
          <li>speed up your searches; and</li>
          <li>recognize you when you use the Apps/Platform.</li>
        </ul>
        <p>
          If you do not want us to collect this information do not download the
          App or delete it from your device. Note, however, that opting out of
          the App's collection of location information will cause its
          location-based features to be disabled.
        </p>
        <p>
          <b>Third-Party Information Collection</b>
        </p>
        <p>
          When you use the SpendTheBits solution including STB Cash App or its
          content, certain third parties collect information about you or your
          device. These third parties may include:
        </p>
        <ul>
          <li>advertisers, ad networks, and ad servers;</li>
          <li>analytics companies;</li>
          <li>your mobile device manufacturer;</li>
          <li>your mobile service provider; and</li>
          <li>
            Anti-money laundering software which collects personal information
            for the purposes of compliance with the Proceeds of Crime (Money
            Laundering) Terrorist Financing Act and regulations and the
            Financial Action Task Force standards and guidelines.
          </li>
        </ul>

        <p>
          These third parties may use cookies alone or in conjunction with other
          tracking technologies to collect information about you when you use
          the App. The information they collect may be associated with your
          Personal Information or they may collect information, including
          Personal Information, about your online activities over time and
          across different websites, apps and other online services. They may
          use this information to provide you with behavioural advertising or
          other targeted content.
        </p>
        <p>
          You can access these websites to learn more about online behavioural
          advertising and how to stop websites from placing cookies on your
          device. Opting out of a network does not mean you will no longer
          receive online advertising. It does mean that the network from which
          you opted out will no longer deliver ads tailored to your web
          preferences and usage patterns.
        </p>
        <p>
          We do not control these third parties' tracking technologies or how
          they use them. If you have any questions about an advertisement or
          other targeted content, you should contact the responsible provider
          directly. For more information about how you can opt out of receiving
          targeted advertising from many providers, see Choices About How We Use
          and Disclose Your Information.
        </p>
        <h4>HOW WE USE YOUR INFORMATION</h4>
        <p>
          We use information that we collect about you or that you provide to
          us, including any Personal Information:
        </p>
        <ul>
          <li>to provide you with the App and its contents;</li>
          <li>
            to provide you with information, products, or services that you
            request from us;
          </li>
          <li>
            to fulfill the purposes for which you provided it or that were
            described when it was collected, or any other purpose for which you
            provide it;
          </li>
          <li>
            to provide notices about your account, including expiration and
            renewal notices;
          </li>
          <li>
            to carry out our obligations and enforce our rights in any contracts
            with you, including for billing and collection or to comply with
            legal requirements;
          </li>
          <li>
            to notify you when App updates are available, and of changes to any
            products or services we offer or provide though it;
          </li>
          <li>
            to improve our App, products or services, or customer relationships
            and experiences;
          </li>
          <li>for marketing purposes;</li>
          <li>
            to allow you to participate in interactive features, social media,
            or other features on our App;
          </li>
          <li>
            to measure or understand the effectiveness of the advertising we
            serve to you and others, and to deliver relevant advertising to you;
            and
          </li>
          <li>for any other purpose with your consent.</li>
          <li>
            Usage information that we collect helps us to improve our App and to
            deliver a better and more personalized experience, including by
            enabling us to:
          </li>
          <li>estimate our audience size and usage patterns;</li>
          <li>recognize you when you use the App.</li>
        </ul>
        <p>
          We may also use your information to contact you about services that
          may be of interest to you, as permitted by law. If you do not want us
          to use your information in this way, please e-mail us at
          privacyofficer@spendthebits.com. For more information, see Choices
          About How We Use and Disclose Your Information.
        </p>
        <h4>DISCLOSURE OF YOUR INFORMATION</h4>
        <p>
          We value your privacy and are committed to protecting your personal
          information. In certain circumstances, we may share your information
          as follows:
        </p>
        <p>
          - Aggregated and Non-Identifying Information: We may share information
          that is aggregated or otherwise non-personal and does not identify any
          individual user without any restriction. This might include usage
          statistics of our App and STB CASH, general demographic information,
          or other insights gathered from our data.
        </p>
        <ul>
          <li>
            Personal Information: The personal information that we collect or
            that you provide may be disclosed as described in this privacy
            policy, including to:
          </li>
          <li>
            Subsidiaries and Affiliates: Our business units, subsidiaries, and
            affiliates for the purposes consistent with this Privacy Policy,
            including to provide you with the SpendTheBits Solution and STB CASH
            services.
          </li>
          <li>
            Business Transfers: In the event of a merger, acquisition,
            divestiture, restructuring, reorganization, dissolution, or any
            other sale or transfer of some or all of SpendTheBits' assets, we
            may transfer the personal information we hold to the relevant third
            party. This includes scenarios where SpendTheBits is involved in a
            bankruptcy, liquidation, or similar proceeding, and personal
            information held by us about our App users and STB CASH customers is
            among the transferred assets.
          </li>
          <li>
            Legal Obligations and Law Enforcement: We may disclose your personal
            information if required to do so by law or in response to valid
            requests by public authorities (e.g., a court or a government
            agency). This may include sharing information for purposes of law
            enforcement, national security, or other issues of public
            importance.
          </li>
          <li>
            To Protect Rights, Property, or Safety: To enforce our terms and
            conditions, protect our operations, rights, privacy, safety, or
            property, and/or that of our affiliates, you, or others, and to
            allow us to pursue available remedies or limit the damages that we
            may sustain.
          </li>
          <li>
            STB CASH Transactions: Given the financial nature of STB CASH,
            certain transactions may necessitate sharing information with
            financial institutions, payment processors, or other entities
            involved in the transaction process, always in accordance with this
            Privacy Policy and applicable laws.
          </li>
          <li>
            to advertisers and advertising networks that require the information
            to select and serve relevant advertisements to you and others. We do
            not disclose data about identifiable individuals to our advertisers,
            but we may provide them with aggregate information about our users.
            We may also use such aggregate information to help advertisers
            target a specific audience. We may use the Personal Information we
            collect from you to display our advertisers' advertisements to their
            target audiences;
          </li>
          <li>
            to third parties to market their products or services to you if you
            have not opted out of these disclosures. For more information, see
            Choices About How We Use and Disclose Your Information;
          </li>
          <li>
            to contractors, service providers, and other third parties we use to
            support our business, such as analytics and search engine providers
            that help us optimize and improve our services. We contractually
            require these third parties to keep Personal Information
            confidential, use it only for the purposes for which we disclose it
            to them, and to process Personal Information following the same
            standards set out in this Privacy Policy;
          </li>
          <li>to fulfill the purpose for which you provide it;</li>
          <li>
            for any other purpose we disclose when you provide the information;
          </li>
          <li>with your consent;</li>
          <li>
            to comply with any court order, law, or legal process, including to
            respond to any government or regulatory request, according to
            applicable law;
          </li>
          <li>
            to enforce our rights arising from any contracts between you and us,
            including the App User Agreement, and for billing and collection;
            and
          </li>
          <li>
            if we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of SpendTheBits including STB Cash
            Platform, our customers, or others. This includes exchanging
            information with other companies and organizations for fraud
            prevention and credit risk reduction.
          </li>
        </ul>
        <h4>TRANSFERRING YOUR PERSONAL INFORMATION</h4>
        <p>
          We may transfer Personal Information that we collect or that you
          provide us to contractors, service providers, and other third parties
          we use to support the App (such as analytics providers that assist us
          with App improvement and optimization) and who are contractually
          obligated to keep Personal Information confidential, use it only for
          the purposes for which we disclose it to them, and to process the
          Personal Information with the same standards set out in this Privacy
          Policy.
        </p>
        <p>
          We may process, store, and transfer your Personal Information in and
          to other countries with different privacy laws that may or may not be
          as comprehensive as Canadian law. In these circumstances, the
          governments, courts, law enforcement, or regulatory agencies of that
          country may be able to obtain access to your Personal Information.
          Whenever we engage a service provider, we require that its privacy and
          security standards comply with this Privacy Policy and applicable
          Canadian laws.
        </p>
        <p>
          By submitting your Personal Information or engaging with the App, you
          consent to this transfer, storage, or processing.
        </p>
        <p>
          We strive to provide you with choices regarding the Personal
          Information you provide to us. We have created mechanisms to provide
          you with the following control over your information:{" "}
          <b>CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION</b>
        </p>
        <ul>
          <li>
            <b>Location Information.</b> You can choose whether to allow the App
            to collect and use real-time information about your device's
            location through the device's privacy settings. If you block the use
            of location information, some parts of the App may be inaccessible
            or not function properly.
          </li>
          <li>
            <b>Third-Party Advertising and Marketing.</b> If you do not want us
            to share your Personal Information with unaffiliated third parties
            for marketing or promotional purposes, you can opt-out by sending us
            an email stating your request to customersupport@spendthebits.com.
          </li>
          <li>
            <b>Promotional Offers from the Company.</b> If you have opted in to
            receive certain emails from us but no longer wish for us to use your
            contact information to promote our own or third parties' products or
            services, you can always opt-out by logging into the App and
            adjusting your user preferences in your account profile by checking
            or unchecking the relevant boxes or by sending us an email stating
            your request to customersupport@spendthebits.com
          </li>
        </ul>
        <h4>DATA SECURITY</h4>
        <p>
          The security of your Personal Information, including details related
          to STB CASH transactions, is of paramount importance to us. To protect
          your data, we implement a blend of physical, electronic, and
          administrative safeguards:
        </p>
        <p>
          We use physical, electronic, and administrative measures designed to
          secure your Personal Information from accidental loss and from
          unauthorized access, use, alteration, and disclosure. We store all
          information you provide to us behind firewalls on our secure servers.
          Any transactions will be encrypted using SSL technology. Our server
          does not store private keys or Passcodes, as this resides on the App.
        </p>
        <p>
          The safety and security of your information also depends on you. Where
          we have given you or you have chosen a password for access to certain
          parts of our App, you are responsible for keeping it confidential. We
          ask you not to share your password with anyone.
        </p>
        <p>
          Unfortunately, the transmission of information via the Internet and
          mobile platforms is not completely secure. Although we do our best to
          protect your Personal Information, we cannot guarantee the security of
          your Personal Information transmitted through the App. Any
          transmission of Personal Information is at your own risk. We are not
          responsible for circumvention of any App privacy settings or security
          measures.
        </p>
        <h4>DATA RETENTION</h4>
        <p>
          Except as otherwise permitted or required by applicable law or
          regulation, we will only retain your Personal Information for as long
          as necessary to fulfill the purposes we collected it for, including
          for the purposes of satisfying any legal, accounting, or reporting
          requirements. Under some circumstances we may anonymize or aggregate
          your Personal Information so that it can no longer be associated with
          you. We reserve the right to use such anonymous and de-identified data
          for any legitimate business purpose without further notice to you or
          your consent.
        </p>
        <h4>CHILDREN UNDER THE AGE OF 13</h4>
        <p>
          Our Apps SpendTheBits Solution including STB CASH Platform, are not
          intended for children under 13 years of age. No one under age 13 may
          provide any Personal Information to or on the App. We do not knowingly
          collect Personal Information from children under 13. If you are under
          13, do not use or provide any information on this App or on or through
          any of its features/register on the App, make any purchases through
          the App, use any of the interactive or public comment features of this
          App, or provide any information about yourself to us, including your
          name, address, telephone number, email address, or any user name you
          may use. If we learn we have collected or received Personal
          Information from a child under 13 without verification of parental
          consent, we will delete that information. If you believe we might have
          any information from or about a child under 13, please contact us at
          customersupport@spendthebits.com.
        </p>
        <h4>ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION</h4>
        <p>
          It is important that the Personal Information we hold about you is
          accurate and current. Please keep us informed if your Personal
          Information changes. By law you have the right to request access to
          and to correct the Personal Information that we hold about you.
        </p>
        <p>
          If you want to review, verify, correct, or withdraw consent to the use
          of your Personal Information you may also send us an email at
          customersupport@spendthebits.com to request access to, correct, or
          delete any Personal Information that you have provided to us. We may
          not be able to delete your Personal Information except by also
          deleting your user account. We may not accommodate a request to change
          information if we believe the change would violate any law or legal
          requirement or cause the information to be incorrect.
        </p>
        <p>
          We may request specific information from you to help us confirm your
          identity and your right to access and to provide you with the Personal
          Information that we hold about you or make your requested changes.
          Applicable law may allow or require us to refuse to provide you with
          access to some or all of the Personal Information that we hold about
          you, or we may have destroyed, erased, or made your Personal
          Information anonymous in accordance with our record retention
          obligations and practices. If we cannot provide you with access to
          your Personal Information, we will inform you of the reasons why,
          subject to any legal or regulatory restrictions.{" "}
        </p>
        <h4>WITHDRAWING YOUR CONSENT</h4>
        <p>
          Where you have provided your consent to the collection, use, and
          transfer of your Personal Information, you may have the legal right to
          withdraw your consent under certain circumstances. To withdraw your
          consent, if applicable, contact us at
          customersupport@spendthebits.com. Please note that if you withdraw
          your consent we may not be able to provide you with a particular
          product or service. We will explain the impact to you at the time to
          help you make your decision.
        </p>
        <h4>CHANGES TO OUR PRIVACY POLICY</h4>
        <p>
          We may update our Privacy Policy from time to time. It is our policy
          to post any changes we make to our Privacy Policy on this page. If we
          make material changes to how we treat our users' Personal Information,
          we will post the new Privacy Policy on this page.
        </p>
        <p>
          We include the date the Privacy Policy was last revised at the top of
          the page. You are responsible for ensuring we have an up-to-date,
          active, and deliverable email address for you, and for periodically
          visiting this Privacy Policy to check for any changes.
        </p>
        <h4>CONTACT INFORMATION AND CHALLENGING COMPLIANCE</h4>
        <p>
          We welcome your questions, comments, and requests regarding this
          Privacy Policy and our privacy practices. Please contact us at:
          Jaskaran Kambo
        </p>
        <p>
          privacyofficer@spendthebits.com and/or via our telephone number:+1
          (516) 515-0156
        </p>
        <p>
          We have procedures in place to receive and respond to complaints or
          inquiries about our handling of Personal Information, our compliance
          with this Privacy Policy, and with applicable privacy laws. To discuss
          our compliance with this Privacy Policy please contact our
          privacyofficer@spendthebits.com using the contact information listed
          above.
        </p>
      </div>
    </div>
  );
};

export default CashPrivacyPolicy;
