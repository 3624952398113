import React from "react";

const LightWrapper = (props) => {
  const wrapperStyle = {
    borderRadius: "10rem",
    background: "var(--lightBgColor)",
    padding: "2.8rem",
  };
  return (
    <div
      className={`defaultLightWrapper ${props.className}`}
      style={{ ...wrapperStyle, ...props.style }}
    >
      {props.children}
    </div>
  );
};

export default LightWrapper;
