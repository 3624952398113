export const faqData = [
  {
    question: "What is SpendTheBits (STB) app?",
    answer:
      "SpendTheBits (STB) app is a digital payment platform that enables users to send, spend, and receive Bitcoin (BTC) using a unique identifier called PayString. It functions similarly to email, where PayString acts as a universal payment identifier for users.",
  },
  {
    question: "What is PayString?",
    answer:
      "PayString is a universal payment identifier assigned to users within the SpendTheBits app. It allows for the generic transfer of value between users, similar to how information is transferred between different user emails (e.g., from Hotmail to Yahoo). PayString utilizes one master address to represent any number of sub-addresses on any payment network, whether centralized or decentralized. This approach ensures privacy by preserving the user's account numbers on the respective networks.",
  },
  {
    question: "How can I use SpendTheBits app?",
    answer:
      "To use the SpendTheBits app, you need to download and install it on your mobile device. Once installed, you can create an account and set up your PayString identifier. With your PayString, you can send, spend, and receive Bitcoin (BTC) conveniently within the app.",
  },
  {
    question: "Is my privacy protected when using SpendTheBits app?",
    answer:
      "Yes, SpendTheBits app prioritizes user privacy. PayString allows for the representation of multiple sub-addresses on different payment networks while preserving the privacy of user account numbers. This ensures that your personal account information remains private and secure.",
  },
  {
    question: "Can I spend Bitcoin using the SpendTheBits app?",
    answer:
      "Absolutely! The SpendTheBits app enables you to spend your Bitcoin (BTC) at various merchants and service providers that accept Bitcoin as a form of payment. Simply use your PayString identifier within the app to initiate transactions and complete purchases.",
  },
  {
    question: "Are there any fees associated with using SpendTheBits app?",
    answer:
      "SpendTheBits app may have certain fees associated with specific transactions or services. It's recommended to review the app's terms and conditions or fee schedule to understand the applicable charges. However, fees related to the Bitcoin network itself, such as transaction fees, are determined by the network and not controlled by SpendTheBits.",
  },
  {
    question: "Is SpendTheBits app available for all mobile platforms?",
    answer:
      "SpendTheBits app is typically available for both iOS and Android platforms. You can check the respective app stores (App Store for iOS and Google Play Store for Android) to download and install the app on your compatible mobile device.",
  },
  {
    question: "What makes SpendTheBits (STB) app unique?",
    answer:
      "SpendTheBits (STB) app provides a unique service of instant Bitcoin payments in Canada. This means that users can make Bitcoin transactions quickly and seamlessly within the country, offering a convenient and efficient payment solution.",
  },
  {
    question: "How does instant Bitcoin payment work with the STB app?",
    answer:
      "The STB app leverages advanced technology and infrastructure to facilitate instant Bitcoin payments. When making a payment using the app, the transaction is processed swiftly, ensuring that the recipient receives the Bitcoin in real-time, offering a seamless payment experience.",
  },
  {
    question:
      "Are instant Bitcoin payments exclusive to Canada with the STB app?",
    answer:
      "Yes, the service of instant Bitcoin payments is currently exclusive to Canada through the STB app. It is designed to cater specifically to users within the country, allowing them to enjoy the benefits of instant Bitcoin transactions.",
  },
  {
    question: "What are the advantages of instant Bitcoin payments?",
    answer:
      "Instant Bitcoin payments offer several advantages. They provide immediate settlement, eliminating the need for lengthy waiting periods typically associated with traditional financial transactions. Instant payments also enhance convenience, allowing users to complete transactions quickly, whether it's for purchases or transferring funds.",
  },
  {
    question:
      "Can I use the STB app for other financial activities besides instant Bitcoin payments?",
    answer:
      "While the STB app specializes in providing instant Bitcoin payments, it may offer additional features and services. It's recommended to explore the app's functionalities to discover other financial activities that may be available within the platform.",
  },
  {
    question: "Is the STB app available for users outside of Canada?",
    answer:
      "As of now, the STB app's instant Bitcoin payment service is specific to Canada. However, it's worth checking with the app's official twitter page for any potential expansion plans or availability in other countries in the future.",
  },
  {
    question: "How secure are instant Bitcoin payments through the STB app?",
    answer:
      "The STB app prioritizes security and employs robust measures to protect user transactions. It typically utilizes encryption protocols, secure storage methods, and follows best practices in the industry to ensure the safety of user funds and sensitive information.",
  },
  {
    question:
      "What is the underlying technology used by the SpendTheBits (STB) app?",
    answer:
      "The STB app has been built and deployed on the XRPL (XRP Ledger), which serves as the layer one blockchain. STB acts as an application layer that facilitates the bridging of different Layer I blockchains or payment rails to other payment rails.",
  },
  {
    question:
      "What does it mean to bridge different Layer I blockchains or payment rails?",
    answer:
      "Bridging different Layer I blockchains or payment rails refers to the process of enabling interoperability and connectivity between these systems. In the case of STB, it allows for the seamless transfer of value between the Bitcoin layer one mainnet and other payment rails using the transactional properties of the XRPL.",
  },
  {
    question: "How does STB bridge Bitcoin using the XRPL?",
    answer:
      "STB leverages the transactional properties of the XRPL to enable the bridging of Bitcoin. It utilizes the XRPL's capabilities to securely and efficiently process transactions, thereby facilitating the transfer of Bitcoin between the Bitcoin layer one mainnet and other payment rails.",
  },
  {
    question: "How does STB bridge Bitcoin using the XRPL?",
    answer:
      "STB leverages the transactional properties of the XRPL to enable the bridging of Bitcoin. It utilizes the XRPL's capabilities to securely and efficiently process transactions, thereby facilitating the transfer of Bitcoin between the Bitcoin layer one mainnet and other payment rails.",
  },
  {
    question:
      "Are there plans to bridge other Layer I blockchains or payment rails in the future?",
    answer:
      "While the present focus of STB is on bridging the Bitcoin layer one mainnet using the XRPL, there may be plans to extend the bridging capabilities to other Layer I blockchains or payment rails in the future. It's recommended to stay updated with the official STB app channels for any announcements regarding additional supported networks.",
  },
  {
    question:
      "What are the benefits of bridging Bitcoin using the XRPL through the STB app?",
    answer:
      "Bridging Bitcoin using the XRPL through the STB app offers several advantages. It enables faster and more efficient transactions by utilizing the XRPL's transactional properties. It also provides users with the ability to interact with Bitcoin on different payment rails, expanding their options for transactions and payments.",
  },
  {
    question:
      "How secure are the bridged transactions between Bitcoin and XRPL through STB?",
    answer:
      "The STB app prioritizes security and follows industry best practices to ensure the safety of bridged transactions. It typically employs encryption protocols, secure storage methods, and robust security measures to protect user funds and sensitive information during the bridging process.",
  },
  {
    question: "What is the role of BitGo in the SpendTheBits (STB) app?",
    answer:
      "BitGo, Inc. serves as the third-party gateway and Bitcoin custodian for all deposits and withdrawals of Bitcoin within the STB app. STB utilizes BitGo's Application Programming Interface (API) to integrate with their services, allowing for secure storage and management of Bitcoin assets.",
  },
  {
    question:
      "What services does BitGo provide as a digital asset trust and custodial company?",
    answer:
      "BitGo, Inc. is a well-known digital asset trust, custodial, and security company. They offer a range of services, including secure storage and management of digital assets like Bitcoin. Their expertise lies in providing solutions for the safekeeping and protection of cryptocurrencies on behalf of individuals and organizations.",
  },
  {
    question: "Why was BitGo chosen as the Bitcoin custodian for the STB app?",
    answer:
      "BitGo's reputation as a trusted digital asset custodian, along with their robust security measures, likely played a role in their selection as the Bitcoin custodian for the STB app. By leveraging BitGo's services and API, STB can ensure the safe handling of Bitcoin deposits and withdrawals for their users.",
  },
  {
    question: "Where is BitGo headquartered?",
    answer: "BitGo, Inc. is headquartered in Palo Alto, California.",
  },
  {
    question:
      "How does the integration with BitGo's API enhance the security of Bitcoin transactions within the STB app?",
    answer:
      "By integrating with BitGo's API, the STB app can leverage the security features and protocols provided by BitGo. These measures may include multi-signature wallets, cold storage solutions, advanced authentication methods, and comprehensive security practices to safeguard the Bitcoin assets held in custody.",
  },
  {
    question:
      "Why is an amount of Bitcoin equivalent to 10 XRP deducted from the user's STB Bitcoin wallet?",
    answer:
      "The deduction of an amount equivalent to 10 XRP from the user's STB Bitcoin wallet is required to activate the XRPL wallet and cover ledger fees on the XRPL network. This ensures that users can participate in transactions and interact with the XRPL effectively.",
  },
  {
    question:
      "What is the fee structure for transfers made through the STB application?",
    answer: (
      <>
        <p>
          The fee structure for transfers in the STB application is as follows:
        </p>
        <ul>
          <li>1% fee for transfers between $1 - $10 of BTC equivalent.</li>
          <li>0.50% fee for transfers between $10 - $100 of BTC equivalent.</li>
          <li>
            0.20% fee for transfers between $100 - $10,000 of BTC equivalent.
          </li>
          <li>
            0.10% fee for transfers between $10,000 - $500,000 of BTC
            equivalent.
          </li>
        </ul>
        <p>
          It's important to note that the fees are based on the equivalent value
          in BTC and the corresponding dollar amount. These fees apply to the
          specific transfer ranges mentioned above.
        </p>
      </>
    ),
  },
  {
    question:
      "Why is the STB app well-suited for facilitating payments in Bitcoin to merchants?",
    answer:
      "The STB app is well-suited for facilitating payments in Bitcoin to both bricks-and-mortar and online merchants because it offers quick, secure, and efficient transaction confirmations. Unlike the current situation with the Bitcoin blockchain, where transactions can be slow to process and inefficient, the STB app addresses pain points such as volatility, settlement time, and payment finality for retail merchants.",
  },
  {
    question: "How does the STB app reduce network transaction fee costs?",
    answer:
      "The STB app significantly reduces network transaction fee costs associated with Bitcoin payments. Instead of paying dollars for each transaction, the app brings the fee down to pennies. This reduction in transaction fees makes Bitcoin payments more cost-effective for both merchants and users.",
  },
  {
    question:
      "How long does it take to complete a transaction when sending and receiving Bitcoin using PayString?",
    answer:
      "When using PayString to send and receive Bitcoin, transactions are completed in a frictionless manner within 3-5 seconds. This quick transaction speed enhances the user experience and allows for swift payment processing between parties.",
  },
  {
    question:
      "How does the STB app utilize the XRP blockchain for Bitcoin transactions?",
    answer:
      "The STB app deploys the user's Bitcoin over the top of the XRP blockchain. This integration enables immediate recording and visibility of all transactions on the XRP Ledger. By leveraging the capabilities of the XRP Ledger, the STB app enhances the efficiency and transparency of Bitcoin transactions.",
  },
  {
    question:
      "How does the STB app utilize the XRP blockchain for Bitcoin transactions?",
    answer:
      "The STB app deploys the user's Bitcoin over the top of the XRP blockchain. This integration enables immediate recording and visibility of all transactions on the XRP Ledger. By leveraging the capabilities of the XRP Ledger, the STB app enhances the efficiency and transparency of Bitcoin transactions.",
  },
  {
    question: "What is the SpendTheBits Merchant Portal?",
    answer:
      "The SpendTheBits Merchant Portal is a platform designed to provide merchants with a seamless and secure payment experience. It is powered by the XRP Ledger, a decentralized blockchain technology. The portal enables merchants to integrate the payment system into their checkout process and accept payments in multiple cryptocurrencies, allowing them to cater to a broader customer base.",
  },
  {
    question: "How does the SpendTheBits Merchant Portal benefit merchants?",
    answer: (
      <>
        <p>
          The SpendTheBits Merchant Portal offers several benefits to merchants,
          including:
        </p>
        <ul>
          <li>
            Seamless integration: Merchants can easily integrate the payment
            system into their checkout process, ensuring a smooth and convenient
            payment experience for customers.
          </li>
          <li>
            Fast transaction settlements: Transactions processed through the
            portal benefit from fast settlement times, allowing merchants to
            receive funds quickly and efficiently.
          </li>
          <li>
            Low transaction fees: Merchants enjoy the advantage of low
            transaction fees, reducing the costs associated with payment
            processing.
          </li>
          <li>
            Real-time transaction monitoring and analytics: The portal provides
            access to real-time transaction monitoring and other analytical
            tools, allowing merchants to gain valuable insights into their
            payment data and optimize their business operations accordingly.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: "Is the SpendTheBits Merchant Portal secure?",
    answer:
      "Yes, security is a top priority for the SpendTheBits Merchant Portal. By utilizing the XRP Ledger, which is built on robust cryptographic protocols and a decentralized network, the portal ensures a secure payment environment for merchants and their customers.",
  },
  {
    question:
      "How can merchants integrate the SpendTheBits payment system into their checkout process?",
    answer:
      "The SpendTheBits Merchant Portal offers easy integration options. Merchants can follow the provided documentation and guidelines to integrate the payment system into their existing checkout process seamlessly. The portal provides the necessary resources and support to facilitate a smooth integration process.",
  },
  {
    question:
      "Can merchants access real-time transaction data and analytics through the SpendTheBits Merchant Portal?",
    answer:
      "Yes, the portal provides merchants with access to real-time transaction monitoring and other analytical tools. Merchants can track transaction data, analyze payment trends, and gain insights that can be utilized for optimizing their business operations and decision-making processes.",
  },
  {
    question:
      "How can retail store owners benefit from the blockchain payment gateway offered by SpendTheBits?",
    answer:
      "Retail store owners can benefit from the blockchain payment gateway by accepting fast payments both in-store and online. The gateway provides an alternative to unfair merchant practices, offering a more transparent and efficient payment solution.",
  },
  {
    question:
      "How does SpendTheBits ensure security and transparency through its decentralized network?",
    answer:
      "SpendTheBits leverages the XRP Ledger, a decentralized blockchain network known for its security and transparency. By utilizing this network, both data and cryptocurrency payment solutions offered by SpendTheBits maintain the same high level of security and transparency.",
  },
  {
    question: "How does SpendTheBits enable real-time transactions?",
    answer:
      "SpendTheBits enables fast transaction settlements by leveraging the XRP Ledger. Bitcoin transfers can be finalized within seconds, compared to the longer transaction times typically associated with the Bitcoin blockchain. By deploying a user's Bitcoin onto the XRP Ledger, users can benefit from faster transactions per second, minimal transaction costs, and environmentally friendly transactions.",
  },
  {
    question: "Can users easily cash out their Bitcoin through SpendTheBits?",
    answer:
      "Yes, SpendTheBits allows users to easily cash out their Bitcoin. Users have the option to withdraw their Bitcoin to their cold wallet or cash out via a crypto exchange, providing flexibility in managing their cryptocurrency assets.",
  },
  {
    question: "Can users easily cash out their Bitcoin through SpendTheBits?",
    answer:
      "Yes, SpendTheBits allows users to easily cash out their Bitcoin. Users have the option to withdraw their Bitcoin to their cold wallet or cash out via a crypto exchange, providing flexibility in managing their cryptocurrency assets.",
  },
  {
    question:
      "What are the common issues with current payment processing companies?",
    answer: (
      <>
        <p>
          Current payment processing companies often face the following issues:
        </p>
        <ol>
          <li>
            Unreasonable processor charges: Many payment processors charge high
            fees, ranging from 2.9% to 4% of the transaction amount, along with
            an additional 30 cents per transaction. These charges can
            significantly impact a merchant's profit margins.
          </li>
          <li>
            Slow transaction speeds: Traditional payment processors may have
            slower transaction speeds, leading to delays in payment confirmation
            and settlement. This can result in a less efficient customer
            experience and hinder cash flow for businesses.
          </li>
          <li>
            Long wait times to access capital: Obtaining access to funds from
            processed transactions can sometimes be a lengthy process with
            traditional payment processors. Merchants may experience delays in
            receiving their funds, which can impact their ability to manage cash
            flow and fulfill operational needs.
          </li>
          <li>
            Complex transaction procedures: Traditional payment processors often
            involve complex and time-consuming transaction procedures. Merchants
            may have to navigate through extensive paperwork, verification
            processes, and compliance requirements, adding administrative
            burdens and slowing down the payment process.
          </li>
        </ol>
      </>
    ),
  },
  {
    question: "How does SpendTheBits address these issues?",
    answer: (
      <>
        <p>
          SpendTheBits aims to alleviate these issues by providing a more
          efficient and cost-effective payment processing solution. Key benefits
          include:
        </p>
        <ol>
          <li>
            Lower transaction costs: SpendTheBits offers lower transaction fees
            compared to traditional processors, reducing the financial burden on
            merchants.
          </li>
          <li>
            Faster transaction speeds: By leveraging the XRP Ledger,
            SpendTheBits enables faster transaction settlements, allowing for
            quicker payment confirmations and improved cash flow.
          </li>
          <li>
            Quick access to funds: SpendTheBits provides expedited access to
            funds, ensuring merchants can access their capital more efficiently
            and meet their operational needs in a timely manner.
          </li>
          <li>
            Simplified transaction procedures: SpendTheBits streamlines the
            payment process, minimizing paperwork, simplifying verification, and
            reducing administrative complexities. This allows for a smoother and
            more convenient experience for merchants.
          </li>
        </ol>
      </>
    ),
  },
];
