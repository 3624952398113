import React, {useEffect} from 'react';
import { About, Banner, Contact, WhoWeAre } from './pageComponents';


const FeaturePage = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);
  return (
    <>
    <Banner />
    <Contact />
    <About />
    <WhoWeAre />
    </>
  )
}

export default FeaturePage