import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./style.module.scss";

const WhoWeAre = () => {
  return (
    <div className={`${classes.whoWeAreContainer} contentContainer`}>
      <Container>
        <Row>
          <Col>
            <h2>Are you ready to accept crypto for your business?</h2>
            <p>
              Major brands like Microsoft, PayPal, and Amazon, have been
              accepting crypto payment methods for products and services for
              some time. As we move further into the future, businesses will
              need to accept online payments in crypto for products and services
              to remain relevant. SpendTheBits empowers the retail merchant and
              owner to accept Bitcoin payments and other digital currencies.
            </p>
            <div className={classes.btnContainer}>
              <a href="tel:+17809529003">Call us</a>
              <a href="mailto:jay@spendthebits.com">Email us</a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhoWeAre;
