import React from "react";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import Logo from "../../../assets/logo.png";
import { Facebook, Twitter, Instagram } from "react-bootstrap-icons";

const Footer = () => {
  return (
    <footer className={`containerWidth ${styles.footerContainer}`} id="contact">
      <div className={styles.footerInner}>
        <div className={styles.topFooter}>
          <div className={styles.footerCol1}>
            <div className={styles.siteLogo}>
              <img src={Logo} alt="SpendTheBits" />
            </div>
            <div className={styles.footerDescription}>
              <p>
                XRP based secure and fast transaction, SpendTheBits is a
                Cryptocurrency payment mobile app which is powered by XRP
                Ledger, a decentralized, open source platform. SpendTheBits
                mobile payment app will enable users to spend their
                cryptocurrency 24/7.
              </p>
              <div className={styles.footerSocial}>
                <a href="https://twitter.com/Spend_The_Bits">
                  <Twitter />
                </a>
                <a href="https://www.facebook.com/spendthebits/">
                  <Facebook />
                </a>
                <a href="https://www.instagram.com/spendthebits/">
                  <Instagram />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.footerCol2}>
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/#about">About</a>
              </li>
              <li>
                <a href="/#products">Products</a>
              </li>
              <li>
                <a href="/#about">Features</a>
              </li>
            </ul>
          </div>
          <div className={styles.footerCol3}>
            <h4>Customer Support</h4>
            <ul>
              <li>
                <Link to={"/terms-condition"}>STB Terms & Conditions</Link>
              </li>
              <li>
                <Link to={"/privacy-policy"}>STB Privacy Policy</Link>
              </li>
              <li>
                <a href={"/stb-cash-terms-condition"}>
                  STB Cash Terms & Conditions
                </a>
              </li>
              <li>
                <a href={"/stb-cash-privacy-policy"}>STB Cash Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className={styles.footerCol4}>
            <h4>Contact Us</h4>
            <ul>
              <li>
                <a href="mailto:hello@spendthebits.com">
                  hello@spendthebits.com
                </a>
              </li>
              <li>
                <a href="tel:+15165150156">+1 (516) 515-0156</a>
              </li>
              <li>
                <h3>MSB Number: M20807854</h3>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.bottomFooter}>
          <div>
            <p>© 2023 Spendthebits - All right reserved</p>
          </div>
          <div>
            <ul>
              <li>
                <Link to={"/terms-condition"}>Terms & Conditions</Link>
              </li>
              <li>
                <Link to={"/privacy-policy"}>- Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
