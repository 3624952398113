import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./style.module.scss";
import PhotoOne from "../../../../assets/photoOne.png";
import PhotoTwo from "../../../../assets/photoTwo.png";
import PhotoFour from "../../../../assets/photoFour.png";

const About = () => {
  return (
    <div className={`${classes.aboutContainer} contentContainer`}>
      <Container>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={`${classes.aboutCol} ${classes.aboutColImg}`}
          >
            <img src={PhotoOne} alt="About" />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} className={classes.aboutCol}>
            <p className={classes.aboutSubHeading}>ABOUT</p>
            <h2>SpendTheBits Merchant Portal</h2>
            <p>
              The SpendTheBits Merchant Portal will facilitate payments in
              Bitcoin to both brick-and-mortar as well as online merchants over
              the XRP Ledger. Users will be able to send fast and cost-effective
              payments to merchants. Transactions will be confirmed in seconds,
              unlike the current situation with Bitcoin, where transactions are
              slow to process and inefficient for retail payment. Connect your
              business to an advanced payment network.
              <br />
              <br />
              Crypto payments — accept cryptocurrency payments today
            </p>
          </Col>
        </Row>
      </Container>
      <Container className={classes.aboutSubContainer}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} className={classes.aboutCol}>
            <h2>What are crypto payments?</h2>
            <p>
              Cryptocurrency payments encompass the use of digital currencies
              like Bitcoin, Litecoin, XRP, and others to purchase products,
              services, and pay subscription fees. Accepting crypto payments can
              be a valuable differentiating factor for your business, setting
              you apart from competitors. With the SpendTheBits Merchant Portal,
              your business can easily process cryptocurrency payments online.
              Our layer-1 blockchain payment gateway supports your business or
              e-commerce site, allowing you to receive payouts in BTC and other
              digital currencies.
            </p>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={`${classes.aboutCol} ${classes.aboutColImg}`}
          >
            <img src={PhotoTwo} alt="About" />
          </Col>
        </Row>
      </Container>
      <Container className={classes.aboutSubContainer}>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={`${classes.aboutCol} ${classes.aboutColImg}`}
          >
            <img src={PhotoFour} alt="About" />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} className={classes.aboutCol}>
            <h2>Current issues with payment processing</h2>
            <p>
              Current payment processing companies charge anywhere from 2.9% to
              4% plus 30 cents per transaction to accept payments online. In
              addition to high costs, payment processors are plagued by issues
              such as:
            </p>
            <ul className={classes.issuesList}>
              <li>Unreasonable processor charges</li>
              <li>Slow transaction speeds</li>
              <li>Long wait times to access capital</li>
              <li>Complex transaction procedures</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
