import React from "react";
import { Container, Row, Col, Stack, Button } from "react-bootstrap";
import classes from "./style.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BookmarkCheck } from "react-bootstrap-icons";

const Contact = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      email: "",
      phone: "",
      companyWebsite: "",
      companyAddress: "",
      referralCode: "",
      acceptCrypto: "",
      acceptConsent: "",
    },
    onSubmit: function (values) {
      alert("Form Submitted");
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      companyName: Yup.string().required(),
      companyAddress: Yup.string().required(),
      email: Yup.string().email().required(),
      phone: Yup.number().required(),
      acceptCrypto: Yup.string().required(),
      acceptConsent: Yup.string().required(),
    }),
  });

  return (
    <div className={`${classes.contactContainer} contentContainer`}>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Stack gap={5}>
              <div className={classes.benefits}>
                <div className={classes.benefitIcon}>
                  <BookmarkCheck />
                </div>
                <div className={classes.benefitText}>
                  <h4>Retail store owners</h4>
                  <p>
                    Accept fast payments in your store and online with our
                    blockchain payment gateway without having to depend on
                    unfair merchant practices.
                  </p>
                </div>
              </div>
              <div className={classes.benefits}>
                <div className={classes.benefitIcon}>
                  <BookmarkCheck />
                </div>
                <div className={classes.benefitText}>
                  <h4>Transparent decentralized network</h4>
                  <p>
                    Leveraging the XRP Ledger, we provide the same level of
                    security and transparency for both our data and
                    cryptocurrency payment solutions.
                  </p>
                </div>
              </div>
              <div className={classes.benefits}>
                <div className={classes.benefitIcon}>
                  <BookmarkCheck />
                </div>
                <div className={classes.benefitText}>
                  <h4>Real-time transaction</h4>
                  <p>
                    Transfers of bitcoin can be finalized in a matter of seconds
                    vs. the 10 to 40-minute transaction time common on the
                    Bitcoin blockchain. By deploying a user’s Bitcoin onto the
                    XRP Ledger, the user gets all of the benefits native to XRP,
                    such as faster transactions per second, minimal transaction
                    cost, and eco-friendly transactions.
                  </p>
                </div>
              </div>
              <div className={classes.benefits}>
                <div className={classes.benefitIcon}>
                  <BookmarkCheck />
                </div>
                <div className={classes.benefitText}>
                  <h4>Payouts</h4>
                  <p>
                    Cash out to BTC instantly. Withdraw your Bitcoin to your
                    cold wallet or simply cash out via a crypto exchange.
                  </p>
                </div>
              </div>
            </Stack>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={classes.contactForm}>
              <h3>Partner Onboarding Form</h3>
              {/* <p>
                Here, let visitors know what will happen when they complete your
                form.
              </p> */}

              <form
                method="POST"
                action="/confirmation-page"
                name="stbcontactform"
                className="stbcontactform"
              >
                <input type="hidden" name="form-name" value="stbcontactform" />
                <div className={classes.nameField}>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Full Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <span className={classes.errorBlock}>
                      {formik.errors.name}
                    </span>
                  )}
                </div>
                <div className={classes.nameField}>
                  <input
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder="Company Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                  />
                </div>
                {formik.touched.companyName && formik.errors.companyName && (
                  <span className={classes.errorBlock}>
                    {formik.errors.companyName}
                  </span>
                )}
                <div className={classes.phoneField}>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Company Phone Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <span className={classes.errorBlock}>
                      {formik.errors.phone}
                    </span>
                  )}
                </div>
                <div className={classes.emailField}>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Company Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <span className={classes.errorBlock}>
                      {formik.errors.email}
                    </span>
                  )}
                </div>
                <div className={classes.nameField}>
                  <input
                    type="text"
                    name="companyWebsite"
                    id="companyWebsite"
                    placeholder="Company Website (If Applicable)"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyWebsite}
                  />
                </div>
                <div className={classes.nameField}>
                  <input
                    type="text"
                    name="companyAddress"
                    id="companyAddress"
                    placeholder="Company Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyAddress}
                  />
                </div>
                {formik.touched.companyAddress &&
                  formik.errors.companyAddress && (
                    <span className={classes.errorBlock}>
                      {formik.errors.companyAddress}
                    </span>
                  )}
                <div className={classes.nameField}>
                  <input
                    type="text"
                    name="referralCode"
                    id="referralCode"
                    placeholder="Referral Code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.referralCode}
                  />
                </div>
                {formik.touched.referralCode && formik.errors.referralCode && (
                  <span className={classes.errorBlock}>
                    {formik.errors.referralCode}
                  </span>
                )}
                <div className={classes.select}>
                  <select name="acceptCrypto" id="acceptCrypto">
                    <option value="none" selected disabled>
                      Do You Currently Accept Cryptocurrency
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className={classes.consentField}>
                  <input
                    type="checkbox"
                    name="acceptConsent"
                    id="acceptConsent"
                  />
                  <label>I agree to the terms and condition</label>
                </div>
                <div className={classes.submit}>
                  <Button variant="contained" type="submit">
                    Send
                  </Button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
