import React from "react";
import styles from "./style.module.scss";
import { LightWrapper } from "../../../../components";
import PointerOneImg from "../../../../assets/easyTouse.png";
import PointerTwoImg from "../../../../assets/encrypted.png";
import PointerThreeImg from "../../../../assets/projectManager.png";
import PointerFourImg from "../../../../assets/saveMoney.png";
// import BgVideo from "../../../../assets/bgVideo.mp4";

const Banner = () => {
  return (
    <div className={`containerWidth ${styles.bannerContainer}`}>
      {/* <video
        src="https://wordpress-871063-3334479.cloudwaysapps.com/wp-content/uploads/2023/03/website-back-Jan115-1.mp4"
        autoPlay
        muted
        loop
      ></video> */}
      <div className={styles.bannerInner}>
        <div className={styles.bannerContent}>
          <h1>SpendTheBits</h1>
          <p>
            Experience the Future of Digital Payments with SpendTheBits Fast,
            Secure, and Affordable Transactions on the go!
          </p>
          <a href="mailto:hello@spendthebits.com" className="defaultBtn">
            EMAIL US
          </a>
        </div>
        <div className={styles.bannerPointer}>
          <LightWrapper className={styles.pointerBlock}>
            <div>
              <img src={PointerOneImg} alt="Pointer" />
            </div>
            <div>
              <p>Easy-to-navigate platform</p>
            </div>
          </LightWrapper>
          <LightWrapper className={styles.pointerBlock}>
            <div>
              <img src={PointerTwoImg} alt="Pointer" />
            </div>
            <div>
              <p>Safe and secure payments</p>
            </div>
          </LightWrapper>
          <LightWrapper className={styles.pointerBlock}>
            <div>
              <img src={PointerThreeImg} alt="Pointer" />
            </div>
            <div>
              <p>Cutting-edge payment technology</p>
            </div>
          </LightWrapper>
          <LightWrapper className={styles.pointerBlock}>
            <div>
              <img src={PointerFourImg} alt="Pointer" />
            </div>
            <div>
              <p>Affordable transaction costs</p>
            </div>
          </LightWrapper>
        </div>
      </div>
    </div>
  );
};

export default Banner;
