import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { Header, Footer } from "./components";
import {
  Home,
  FeaturePage,
  PrivacyPolicy,
  TermsCondition,
  Faq,
  CashTermsCondition,
  CashPrivacyPolicy,
} from "./pages";

function App() {
  const location = useLocation();

  return (
    <div className="pageContainer">
      <Header static={location.pathname === "/" ? true : false} onClick />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/feature" element={<FeaturePage />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route
            path="/stb-cash-terms-condition"
            element={<CashTermsCondition />}
          />
          <Route
            path="/stb-cash-privacy-policy"
            element={<CashPrivacyPolicy />}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
