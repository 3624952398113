import React, {useEffect} from "react";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);
  return (
    <div className={`containerWidth ${styles.tcContainer}`}>
      <div>
        <h1>SPENDTHEBITS INC. USER AGREEMENT</h1>
        <p>
          PLEASE CAREFULLY READ THE FOLLOWING TERMS. BY CLICKING ON “I AGREE”,
          YOU INDICATE THAT YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF
          THIS AGREEMENT AND THE SPENDTHEBITS USER PRIVACY POLICY
          <a href="https://spendthebits.com/privacy-policy/">
            https://spendthebits.com/privacy-policy/
          </a>{" "}
          AND HEREBY ENTER INTO A LEGALLY BINDING AGREEMENT WITH SPENDTHEBITS.
          IF YOU DO NOT AGREE WITH THESE TERMS DO NOT CLICK ON “I AGREE” BELOW
          <br />
          <br />
          This agreement is made between SpendTheBits Inc. (“SpendTheBits”), and
          you (“You”, “Your” and other similar words). Your access to and use of
          the SpendTheBits Wallet is governed solely by the terms of this
          Agreement which supersedes and replaces the terms of any other prior
          agreement, writing or understanding between You and SpendTheBits.
          “You” includes all your heirs, trustees, administrators and other
          legal representatives.
          <br />
          <br />
          SpendTheBits carries on the business of providing users the ability to
          send bitcoin or other Virtual Currency (as defined below) onto the
          mobile wallet application (the “App”), and use the Wallet (as defined
          below) located in the App to store their Virtual Currency, to send
          their Virtual Currency to friends and family and to buy goods and
          services with their Virtual Currency, all while enjoying cheaper and
          faster transactions as a result of the use of the XRP ledger
          (“SpendTheBits Solution”).
          <br />
          <br />
          SpendTheBits reserves the right to modify the terms and conditions of
          this Agreement or its policies relating to the Software and the
          SpendTheBits Solution at any time, effective upon posting of an
          updated version of this Agreement. Your continued use of the
          SpendTheBits Solution following notice of such change means that you
          agree to and accept the Agreement as amended. If you do not agree to
          any modification of this Agreement, you must immediately stop using
          the Software and the SpendTheBits Solution and notify us that you are
          terminating this Agreement.
          <br />
          <br />
          BY YOU USING THE SOFTWARE, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE
          READ AND AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS
          AGREEMENT (WHICH ALSO INCLUDES THE SPENDTHEBITS USER PRIVACY POLICY
          <br />
          <br />
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
        <h1>TERMS AND CONDITIONS</h1>
        <h2>1. DEFINITIONS. As used in this Agreement:</h2>
        <ol>
          <li>
            “Agreement” means these Terms and Conditions and the SpendTheBits
            Solution User Privacy Policy incorporated by reference into this
            Agreement.
          </li>
          <li>
            “Applicable Laws” means all laws, regulations and codes applicable
            to SpendTheBits and You.
          </li>
          <li>
            “Confidential Information” means all information regarding
            SpendTheBits’s business, including, without limitation, the
            SpendTheBits Solution, the Wallet, the App and any software and
            other confidential or proprietary information disclosed under this
            Agreement.
          </li>
          <li>
            “Email Address” means a single valid email address provided by You.
          </li>
          <li>
            “Fork” means a change to the underlying protocol of a Virtual
            Currency network that results in more than one version of a Virtual
            Currency, the result of which may be one or more versions that are
            not supported by SpendTheBits.
          </li>
          <li>
            “Personal Information” means information about an identifiable
            individual, including You, that is collected, used and disclosed in
            the course of delivering the SpendTheBits Solution.
          </li>
          <li>
            “Services” means the services provided by SpendTheBits to You.
          </li>
          <li>
            “Software” means the online software used to provide the
            SpendTheBits Solution and Wallet and delivered by SPENDTHEBITS to
            You, as made available by SpendTheBits from time-to-time.
          </li>
          <li>
            “SpendTheBits User Privacy Policy” means the policy governing the
            collection, use, disclosure and retention of information of an
            identifiable individual. You can access here:
            <a href="https://web.archive.org/web/20230403171758/https://spendthebits.com/privacy-policy/">
              {" "}
              https://spendthebits.com/privacy-policy/
            </a>
          </li>
          <li>
            “SpendTheBits Parties” means SpendTheBits and its officers,
            directors, agents, subsidiaries, suppliers and employees.
          </li>
          <li>“User” means any user of the Software, including You.</li>
          <li>
            “User System” means the technology, including hardware and software
            used by You to receive or access the Software.
          </li>
          <li>
            “Virtual Currency” and “Virtual Currencies” means a cryptocurrency,
            digital currency, digital asset, cryptoasset or other such similar
            term describing, for example, Bitcoin or Ether but does not include
            a derivative of a virtual currency, or a security, as defined under
            applicable law.
          </li>
          <li>
            “Wallet” means the wallet software published by SpendTheBits Inc.,
            consisting of software that permits you to self-custody Virtual
            Currency, organise network addresses, view transaction history and
            transact in Virtual Currencies as contemplated in this Agreement.
          </li>
          <li>
            “Your Data” means any data, information or information submitted by
            You and includes, without limitation, Personal Information.
          </li>
        </ol>
        <h2>2. Software</h2>
        <ol>
          <li>
            License to the Software. Subject to You complying with each term of
            this Agreement, SpendTheBits hereby grants to You a limited,
            revocable, personal, non-sub-licensable, non- transferable,
            non-exclusive right to access and use the Software to access the
            SpendTheBits Solution in accordance with this Agreement solely for
            personal reasons and not for resale or to provide services to third
            parties.
          </li>
          <li>
            No Sharing. Your access to the Software cannot be shared with
            anyone. You will maintain a single user account represented by Your
            Email Address on the App. You will only use the Software and access
            the SpendTheBits Solution under this single identity and will not
            share Your password or PIN with any other person or permit another
            person to interact with the Software using Your account information,
            PIN or password.
          </li>
          <li>
            Software. SpendTheBits shall use commercially reasonable efforts to
            (a) maintain the security of the Software; and (b) make the Software
            available in accordance with this Agreement. The Software will be
            unavailable from time to time for regularly scheduled and other
            maintenance and updates. SpendTheBits does not guarantee any uptime,
            speed or availability of the Software or the SpendTheBits Solution
            to You and Specifically denies any responsibility or liability for
            any loss, damages or claim arising out of, or related to, any
            unavailability or speed of the software (or part thereof) at any
            time. SPENDTHEBITS may change, suspend or discontinue any aspect of
            the SpendTheBits Solution or Software at any time without notice or
            liability. Further, You acknowledge and agree that SPENDTHEBITS’s
            activities under this agreement are limited only to providing
            technology to facilitate the SpendTheBits Solution and agree that
            SPENDTHEBITS only provides the Software, and the Software operates
            from within Canada. SpendTheBits does not facilitate Virtual
            Currency exchange or trading services and has no control over who
            You choose to send your Virtual Currency to and what You choose to
            spend your Virtual Currency on.
          </li>
        </ol>
        <h2>3. YOUR USE OF THE SOFTWARE</h2>

        <ol>
          <li>
            <p>
              <strong>Access and Security Guidelines.</strong> You will use Your
              real name and Your own valid Email Address and create Your own 4
              digit personal identification number (PIN) and password to use in
              association with Your Email Address for access to and use of the
              Software and the SpendTheBits Solution. The SpendTheBits Solution
              automatically enables two-factor authentication by default. Once
              the onboarding process is complete and the PIN is finalized, You
              will have the option of logging in either via the PIN or biometric
              authentication, which is automatically enabled upon successful
              onboarding. We do not store any biometric information on our
              servers, that information is entirely stored on Your User System.
              You are solely responsible for ensuring the security and
              confidentiality of Your PIN. You shall not share your PIN with
              anyone at any time. You will prevent unauthorized access to or use
              of the Software or the SpendTheBits Solution and notify
              SPENDTHEBITS promptly of any such unauthorized use. You are
              responsible for the use of the Software and the SpendTheBits
            </p>
            <p>
              Solution by You or any other person using Your PIN. You shall
              ensure that the User System is secure and uses an up-to-date
              commercially available anti-virus and security software.
              SPENDTHEBITS has no responsibility whatsoever for any User System.
              You will not use Your access to the SpendTheBits Solution to: (a)
              harvest, collect, gather or assemble information or data regarding
              the SpendTheBits Solution or information or data of other
              SpendTheBits users without their consent; (b) access, modify or
              copy any data or information of the SpendTheBits Solution or other
              SpendTheBits users without their consent; (c) knowingly interfere
              with or disrupt the integrity or performance of the SpendTheBits
              Solution or the data contained therein; or (d) harass or interfere
              with another of user’s use and enjoyment of the SpendTheBits
              Solution. You will, at all times, comply with all local,
              provincial/territorial and federal laws of Canada when using the
              Software and accessing the SpendTheBits Solution. SpendTheBits
              makes no representations that the Software or SpendTheBits
              Solution is appropriate, legal or available for use in other
              jurisdictions. Those who access or use the Software and
              SpendTheBits Solution from other jurisdictions do so at their own
              volition and risk, and are responsible for compliance with all
              applicable law (local, domestic and international).
            </p>
          </li>
          <li>
            <strong>Your Data.</strong> You are solely responsible for Your Data
            that you provide and You will not provide, post or transmit any
            data, information, content or material that: (a) infringes,
            misappropriates or violates any intellectual property rights,
            publicity/privacy rights or other rights of any third party, or any
            law or regulation; or (b) contains any viruses or programming
            routines intended to damage, surreptitiously intercept or
            expropriate any system, data or personal information. SpendTheBits
            may take any remedial action if Your Data violates this Section 3.2;
            however, SpendTheBits is under no obligation to review any data,
            information, content or material on the SpendTheBits Solution for
            accuracy or potential liability.
          </li>
          <li>
            <strong>Fees.</strong> In consideration of Your use of the
            SpendTheBits Solution, You agree to pay any and all fees as
            described in the App and Our website, which fees shall be updated
            from time to time by Us at our discretion, and published on the App
            and Our website.
          </li>
          <li>
            <strong>Use Restrictions.</strong> You are responsible for all
            activities that occur under Your account. You will not, and will not
            attempt to, do any of the following: (a) reverse engineer,
            disassemble or decompile any component of the Software or the
            SpendTheBits Solution; (b) interfere in any manner with the
            operation of the Software or the SpendTheBits Solution or the
            hardware and network used to operate the Software; (c) transfer any
            of your rights under this Agreement, use the Software or access the
            SpendTheBits Solution for the benefit of a third party or operate a
            service bureau; (d) modify, copy or make derivative works based on
            any part of the Software or the SpendTheBits Solution; (e) otherwise
            use the Software or access the SpendTheBits Solution in any manner
            that exceeds the scope of use permitted under Section 2.1; (f) use
            the Software or access the SpendTheBits Solution in any way that is
            unlawful, libellous, defamatory, obscene, pornographic, indecent,
            lewd, suggestive, harassing, threatening, invasive of privacy or
            publicity rights, abusive, inflammatory, fraudulent or otherwise
            objectionable; (g) use the Software or access the SpendTheBits
            Solution in any way that would constitute, encourage or provide
            instructions for a criminal offense, violate the rights of any
            party, or that would otherwise create liability or violate any
            local, provincial, national or international law; (h) use the
            Software or access the SpendTheBits Solution in any way that may
            infringe any patent, trademark, trade secret, copyright or other
            intellectual or proprietary right of any party anywhere in the
            world; (i) use the Software or access the SpendTheBits Solution in
            any way that discloses personal information about another person,
            unless permitted by this Agreement or that impersonates any person
            or entity or otherwise misrepresents Your affiliation with a person
            or entity; (j) use the Software or access the SpendTheBits Solution
            in any way for unsolicited promotions, political campaigning,
            advertising or solicitations; (k) use the Software or access the
            SpendTheBits Solution in any way that introduces or spreads viruses,
            corrupted data or other harmful, disruptive or destructive files in
            to the Software or programming routines intended to damage,
            surreptitiously intercept or expropriate any system, data or
            personal information; or (l) use the Software or access the
            SpendTheBits Solution in any way that, in the sole judgment of
            SpendTheBits, is objectionable or which restricts or inhibits any
            other person from using or enjoying the SpendTheBits Solution or
            which may expose SpendTheBits or its Users to any harm or liability
            of any type, or creates or imposes an inappropriate load or creates
            large bandwidth-consuming transactions on the SpendTheBits Solution.
          </li>
          <li>
            <strong>No Competitive Use.</strong> You may not subscribe for, use
            or access the Software if You are (or are engaged by) a competitor
            of SpendTheBits, except with SpendTheBits’s prior written consent.
            In addition, You may not subscribe for, use or access the Software
            for purposes of monitoring its availability, performance or
            functionality, or for any other benchmarking or competitive
            purposes.
          </li>
          <li>
            <strong>Suspension or Cancellation of Your Use.</strong> In addition
            to any other right or remedy that SpendTheBits may have under this
            Agreement or at law, SpendTheBits may terminate or suspend
            (partially or completely) Your access and use of the Software
            without prior notice for any reason, but SpendTheBits will confirm
            such termination or suspension by subsequent notice to You. You are
            responsible for any claims, fees, fines, penalties and other
            liability incurred by us or others caused by your use of the
            SpendTheBits Solution.
          </li>
          <li>
            <strong>Consent and License You Give Us.</strong> You hereby grant
            to and consent to SpendTheBits collecting, using, storing and
            disclosing your Personal Information in accordance with the
            SpendTheBits Solution User Privacy Policy.
          </li>
          <li>
            <strong>Content.</strong> SpendTheBits does not represent or
            guarantee the truthfulness, accuracy or reliability of and
            SpendTheBits takes no responsibility and assumes no liability for
            any content posted, stored or uploaded by You, any user of the
            SpendTheBits Solution, or any third party on, in or to the
            SpendTheBits Solution, or for any loss or damage arising therefrom,
            nor is SpendTheBits liable for any mistakes, defamation, slander,
            libel, omissions, falsehoods, obscenity, pornography or profanity
            You may encounter. YOUR USE OF THE SpendTheBits SOLUTION IS AT YOUR
            OWN RISK OF BEING EXPOSED TO SUCH MATERIAL.
          </li>
        </ol>
        <h2>4. THE WALLET</h2>
        <ol>
          <li>
            Under no circumstances should You attempt to store Virtual
            Currencies in Your Wallet that the Wallet does not support.
          </li>
          <li>
            When You create a Wallet, the Software generates a cryptographic
            private and public key pair that you may use to send and receive any
            supported Virtual Currency via the relevant Virtual Currency
            network.{" "}
            <b>
              YOU MUST STORE, OUTSIDE OF THE SERVICES, A BACKUP OF ALL WALLET
              CREDENTIALS, INCLUDING YOUR PASSPHRASES, IDENTIFIERS, BACKUP
              PHRASES, PRIVATE KEYS AND NETWORK ADDRESSES
            </b>
            . If you do not maintain a backup of your Wallet data outside of the
            Services, you will not be able to access Virtual Currency previously
            accessed using your Wallet in the event that we discontinue or no
            longer offer some or all of the Services or may otherwise lose
            access to Virtual Currency. Also, in the event you delete the App
            and reinstall it and you lose your backup data, you will lose your
            Virtual Currency forever. We are not responsible for maintaining
            this data on your behalf.
          </li>
          <li>
            As the owner of Virtual Currency in your Wallet, you shall bear all
            risk of loss of such Virtual Currency. SpendTheBits shall not have
            any liability for fluctuations in the fiat currency value of Virtual
            Currency held in your Wallet.
          </li>
          <li>
            You control the Virtual Currency held in your Wallet. At any time,
            subject to outages, downtime, and other applicable policies, you may
            withdraw your Virtual Currency by sending it to a different
            blockchain address controlled by you or a third party. In the event
            you wish to transfer Virtual Currency into the Wallet, you may be
            prompted to set up a backup phrase in order to do so.
          </li>
          <li>
            In order to be completed, any Virtual Currency transaction created
            with the Wallet must be confirmed and recorded in the Virtual
            Currency ledger associated with the relevant Virtual Currency
            network. Such networks are decentralised, peer-to-peer networks
            supported by independent third parties, which are not owned,
            controlled or operated by SpendTheBits.
          </li>
          <li>
            SpendTheBits has no control over any Virtual Currency network and
            therefore cannot and does not ensure that any transaction details
            you submit via our Services will be confirmed on the relevant
            Virtual Currency network. The transaction details you submit via our
            Services may not be completed, or may be substantially delayed, by
            the Virtual Currency network used to process the transaction. We do
            not guarantee that the Wallet can transfer title or right in any
            Virtual Currency or make any warranties whatsoever with regard to
            title.
          </li>
          <li>
            Once transaction details have been submitted to a Virtual Currency
            network, we cannot assist you to cancel or otherwise modify your
            transaction or transaction details. SpendTheBits has no control over
            any Virtual Currency network and does not have the ability to
            facilitate any cancellation or modification requests.
          </li>
          <li>
            In the event of a Fork or other network disruption, SpendTheBits may
            not be able to support activity related to your Virtual Currency. In
            the event of a Fork, the transactions may not be completed,
            completed partially, incorrectly completed, or substantially
            delayed. SpendTheBits is not responsible for any loss incurred by
            you caused in whole or in part, directly or indirectly, by a Fork or
            other network disruption.
          </li>
          <li>
            With respect to the Wallet, SpendTheBits does not receive or store
            your PIN, nor any keys, network addresses or transaction history. We
            cannot assist you with PIN retrieval. You are solely responsible for
            remembering, storing and keeping secret your PIN. Any Virtual
            Currency you have associated with such Wallet may become
            inaccessible if you do not know or keep secret your PIN. Any third
            party with knowledge of one or more of your credentials (including,
            without limitation, a backup phrase, wallet identifier or password)
            can dispose of Virtual Currency in your Wallet
          </li>
          <li>
            When you create a Wallet, you must: (a) create a strong password
            that you do not use for any other website or online service; (b)
            provide accurate and truthful information;
          </li>
          <li>
            (c) protect and keep secret all credentials for the Wallet; (d)
            protect and keep secret your PIN; (e) protect access to your device
            and your Wallet; (f) promptly notify us if you discover or otherwise
            suspect any security breaches related to your Wallet; and (g) use
            the backup functionality provided through the Wallet and safeguard
            your backup files. You agree to take responsibility for all
            activities that occur with your Wallet and accept all risks of any
            authorised or unauthorised access to your Wallet, to the maximum
            extent permitted by law.
          </li>
        </ol>
        <h2>5. YOUR WARRANTIES AND PROMISES TO SPENDTHEBITS</h2>

        <ol>
          <li>
            <strong>Your Warranties.</strong> You hereby represent and warrant
            to SpendTheBits now and at all times during your access and use of
            the Software and the SpendTheBits Solution, and SpendTheBits is
            relying on Your representations and warranties in allowing You to
            use and access the Software and the SpendTheBits Solution, that: (i)
            you are a resident of a jurisdiction in which the Software and the
            SpendTheBits Solution, and your use of the Software and the
            SpendTheBits Solution is not contrary to Applicable Laws; (ii) any
            information that You provide at any time to SpendTheBits is true,
            accurate, and complete; (iii) You have all necessary rights to
            provide any of Your Data, or any other information, data, materials
            or content to SpendTheBits for the purposes described in this
            Agreement and (iv) You agree to hold SpendTheBits’s Confidential
            Information in strict confidence and not to use such Confidential
            Information for any purpose except as expressly permitted hereunder.
          </li>
          <li>
            <strong>Security Vulnerability.</strong> In order to maintain the
            Software as secure as possible, You must not disclose any security
            vulnerability to any person except SpendTheBits. Immediately upon
            discovering any issue or problem that may be pose a risk to the
            security or use of the SpendTheBits Solution or to Your Data (or any
            data of any other user of the Software), you must notify us by
            contacting{" "}
            <a href="mailto:security@SpendTheBits.com">
              security@SpendTheBits.com
            </a>
          </li>
        </ol>
        <h2>6. OWNERSHIP</h2>
        <ol>
          <li>
            <strong>SpendTheBits Solution and Technology.</strong> SpendTheBits
            exclusively owns and retains all right, title and interest in and to
            the Software, the Wallet and the SpendTheBits Solution and all
            software, materials, formats, interfaces, information, text, data,
            graphics, images, video, logos, icons, audio, content, computer
            code, SpendTheBits’s Confidential Information, and SpendTheBits
            proprietary information and technology used by SpendTheBits or
            provided to You in connection with the SpendTheBits Solution and any
            compilation thereof (the “SpendTheBits Technology”). “SpendTheBits
            Inc.,” the SpendTheBits logo, and other SpendTheBits logos and
            product and service names are or may be trademarks of SpendTheBits
            (the “SpendTheBits Marks”). Without our prior written permission,
            and except as solely enabled by any link as provided by us, you
            agree not to display or use the SpendTheBits Marks, whether or not
            registered, in any manner. You must preserve all copyright,
            trademarks, service marks, and other proprietary notices contained
            in the original SpendTheBits Technology on any copy you make of the
            SpendTheBits Technology. The SpendTheBits Technology is protected by
            intellectual property rights under the laws of Canada and other
            countries, including copyright and trademark laws and are owned by
            or licensed to SpendTheBits. Other than as expressly licensed to You
            in this Agreement, no license or other rights in the SpendTheBits
            Technology are granted to the You, and all such rights are hereby
            expressly reserved by SpendTheBits. SpendTheBits shall have a
            royalty- free, worldwide, transferable, sublicenseable, irrevocable,
            perpetual license to use or incorporate into the Software any
            suggestions, enhancement requests, recommendations or other feedback
            provided by You, relating to the Software.
          </li>
          <li>
            <strong>Your Data.</strong> You retain all ownership in and to Your
            Data, including Data that is Personal Information. You grant to
            SpendTheBits all necessary licenses in and to Your Data as necessary
            for SpendTheBits,
          </li>
        </ol>
        <h2>7. TERM AND TERMINATION</h2>
        <ol>
          <li>
            <strong>Term.</strong> The term of this Agreement will commence on
            the date you sign up for the Software or begin to use the App and
            the SpendTheBits Solution and continues until terminated in
            accordance with the provisions of this Agreement.
          </li>
          <li>
            <strong>Termination.</strong> You may cease using the Software at
            any time. SpendTheBits may cease providing You the Software (or any
            portion of it) at any time without notice. SpendTheBits may
            terminate this Agreement immediately, and at any time, if You
            violate your obligations under this Agreement. Upon the termination
            of this Agreement for any reason, (a) the license granted to
            SpendTheBits in respect of Your Data survives for so long as
            SpendTheBits is required by law to retain such information; (b)
            SpendTheBits will cease providing and You will cease using the
            Software; and (c) SpendTheBits will be entitled to retain and use
            Your Data in accordance with the terms of this Agreement, subject
            always to the laws of the Province of Alberta and applicable laws of
            Canada therein. The rights and duties of the parties under Sections
            3.7, 4, 6, 7.2, 8, 9, 10, and 11 will survive the termination or
            expiration of this Agreement.
          </li>
        </ol>
        <h2>8. WARRANTY DISCLAIMER</h2>
        <ol>
          <li>
            Warranty Disclaimed. SpendTheBits makes no warranty concerning the
            SpendTheBits Solution or Software. ACCORDINGLY, THE SOFTWARE, THE
            SPENDTHEBITS SOLUTION AND ALL OTHER DATA OR MATERIALS PROVIDED IN
            CONNECTION WITH THIS AGREEMENT BY SPENDTHEBITS AND ITS SUPPLIERS ARE
            PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT REPRESENTATIONS OR
            WARRANTIES OF ANY KIND. SPENDTHEBITS AND ITS SUPPLIERS MAKE NO OTHER
            WARRANTIES, EXPRESS OR IMPLIED, BY OPERATION OF LAW OR OTHERWISE,
            INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF NON-
            INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR
            ANY IMPLIED WARRANTIES ARISING OUT OF COURSE OF PERFORMANCE, COURSE
            OF DEALING OR USAGE OF TRADE. SPENDTHEBITS DOES NOT WARRANT THAT THE
            SOFTWARE WILL BE ERROR-FREE, ACCURATE, TIMELY, UNINTERRUPTED,
            COMPLETELY SECURE, OR VIRUS- FREE.
          </li>
          <li>
            THE SOFTWARE PERMITS YOU TO LINK TO OTHER WEBSITES OR RESOURCES ON
            THE INTERNET, AND OTHER WEBSITES OR RESOURCES MAY CONTAIN LINKS TO
            SPENDTHEBITS’S WEBSITES. THESE OTHER WEBSITES ARE NOT UNDER
            SPENDTHEBITS’S CONTROL. YOU ACKNOWLEDGE THAT SPENDTHEBITS IS NOT
            RESPONSIBLE OR LIABLE FOR THE CONTENT, FUNCTIONS, ACCURACY,
            LEGALITY, APPROPRIATENESS OR ANY OTHER ASPECT OF SUCH WEBSITES OR
            RESOURCES. THE INCLUSION OF ANY SUCH LINK DOES NOT IMPLY ENDORSEMENT
            BY SPENDTHEBITS OR ANY ASSOCIATION WITH ITS OPERATORS. YOU FURTHER
            ACKNOWLEDGE AND AGREE THAT SPENDTHEBITS SHALL NOT BE RESPONSIBLE OR
            LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR
            ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH THE USE OF OR RELIANCE
            ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY
            SUCH WEBSITE OR RESOURCE.
          </li>
        </ol>
        <h2>9. INDEMNITY</h2>
        <ol>
          <li>
            <strong>By You.</strong> If any claim, action or demand is
            instituted against SpendTheBits Parties (a) arising out of or
            relating to your use of the SpendTheBits Solution, the App, the
            Wallet or Software (including, without limitation, any claims
            relating to any violation by you of any law, regulation or order
            (local, domestic or foreign) in relation to the use of the Software
            or App or through the SpendTheBits Solution); or (b) alleging that
            Your Data, or the use of Your Data pursuant to this Agreement,
            infringes, violates or misappropriates any intellectual property
            rights or other right of a third party or otherwise causes harm to a
            third party, You will indemnify, defend and hold harmless
            SpendTheBits Parties from such action at Your own expense on behalf
            of SpendTheBits Parties and shall pay all damages, fees, fines,
            costs or other amounts attributable to such claim, action or demand.
            You shall have no obligation under this Section for any claim or
            action that arises solely and directly out of a material breach of
            this Agreement by SpendTheBits.
          </li>
          <li>
            <strong>Conditions.</strong> You may settle or compromise a claim
            without SpendTheBits’s prior approval of any such settlement or
            compromise only if (A) such settlement involves no finding or
            admission of any breach by SpendTheBits of any obligation to You or
            any third party, (B) such settlement has no effect on any defense
            that SpendTheBits may assert in any such claim, and (C) such
            settlement does not impose any condition or obligation on
            SpendTheBits of any kind (including, without limitation, any
            financial obligation). Upon Your assumption of the defense of such
            claim, SpendTheBits will cooperate with You in such defense, at Your
            expense, except to the extent that SpendTheBits has any adverse or
            different interest than You.
          </li>
        </ol>
        <h2>10. LIMITATION OF LIABILITY</h2>
        <p>
          SPENDTHEBITS’S TOTAL CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL
          CLAIMS ARISING FROM OR IN CONNECTION WITH THIS AGREEMENT (UNDER ANY
          LEGAL THEORY INCLUDING CLAIMS IN CONTRACT OR TORT), THE SOFTWARE AND
          THE SPENDTHEBITS SOLUTION, WILL NOT EXCEED THE AMOUNTS ACTUALLY PAID
          TO SPENDTHEBITS, IF ANY, BY YOU IN THE SIX MONTH PERIOD IMMEDIATELY
          PRECEDING YOUR FORMAL WRITTEN NOTICE OF THE CLAIM FOR LIABILITY
          HEREUNDER. ALL CLAIMS THAT YOU MAY HAVE AGAINST SPENDTHEBITS WILL BE
          AGGREGATED TO SATISFY THIS LIMIT AND MULTIPLE CLAIMS WILL NOT ENLARGE
          THIS LIMIT. IN NO EVENT WILL SPENDTHEBITS BE LIABLE FOR SPECIAL,
          INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN
          CONNECTION WITH THIS AGREEMENT (UNDER ANY LEGAL THEORY INCLUDING
          CLAIMS IN CONTRACT OR TORT), INCLUDING, BUT NOT LIMITED TO,
          INTERRUPTED COMMUNICATIONS, LOST DATA OR LOST PROFITS, AND DAMAGES
          THAT RESULT FROM INCONVENIENCE, DELAY OR LOSS OF USE OF ANY
          INFORMATION OR DATA OR OF THE SPENDTHEBITS SOLUTION, APP OR SOFTWARE,
          EVEN IF SPENDTHEBITS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY
          LIMITED REMEDY PROVIDED HEREIN.
        </p>
        <h2>11. GENERAL PROVISIONS</h2>
        <ol>
          <li>
            <strong>Express Consent.</strong> By entering into this Agreement,
            You expressly consent to receiving electronic messages (emails, text
            messages, instant messages or any similar form of message) from
            SpendTheBits which messages shall provide information related to the
            SpendTheBits Solution. If You wish to use the SpendTheBits Solution
            on a mobile device, You further expressly consent to the
            installation of software (and any updates) required to use the
            SpendTheBits Solution as an App, including the installation of the
            Wallet, on mobile devices. You may contact SpendTheBits at the
            address, phone number and email located below in order to cancel
            Your consent to receive electronic messages and/or software updates
            at any time.
          </li>
          <li>
            <strong>Publicity.</strong> SpendTheBits may make public
            announcements, including but not limited to, press releases and
            media announcements, of the existence of this Agreement, the
            SpendTheBits Solution and the Software.
          </li>
          <li>
            <strong>Assignment.</strong> You may not assign any rights or
            obligations arising under this Agreement, whether by operation or
            law or otherwise, without the prior written consent of SpendTheBits.
            You agree that SpendTheBits may subcontract certain aspects of the
            SpendTheBits Solution to qualified third parties, provided that any
            such subcontracting arrangement will not relieve SpendTheBits of any
            of its obligations hereunder. Subject to the foregoing limitation,
            this Agreement shall inure to the benefit of and shall be binding on
            the heirs, legal representatives, successors and assignees of the
            parties.
          </li>
          <li>
            <strong>Disputes.</strong> You agree that any action at law or in
            equity arising out of or relating to this Agreement shall be filed
            only in the provincial and federal courts located in Edmonton,
            Alberta and you hereby irrevocably and unconditionally consent and
            submit to the exclusive jurisdiction of such courts over any suit,
            action or proceeding arising out of this Agreement or Your use of
            the Software, the App and SpendTheBits Solution. To the extent
            permitted by law, You agree that You will not bring a claim under
            this Agreement more than 12 months from when Your claim first
            arises.
          </li>
          <li>
            <strong>Injunctions.</strong> Notwithstanding the foregoing, if you
            breach or threaten to breach any provision of this Agreement You
            acknowledge that irreparable harm will result to SpendTheBits if You
            breach any obligation under this Agreement and You acknowledge and
            agree that such a breach would not be properly compensable by an
            award of damages. Accordingly, You agree that SpendTheBits shall be
            entitled to, in addition to other available remedies and damages,
            injunctive relief or other equitable relief enjoining such breach at
            the earliest possible date.
          </li>
          <li>
            <strong>Governing Law and Venue.</strong> This Agreement will be
            governed by and construed in accordance with the laws of the
            Province of Alberta, Canada without giving effect to conflict of
            laws principles. Subject to the mandatory arbitration provisions,
            any action or proceeding arising from or relating to this Agreement
            must be brought in a court sitting in Alberta, Canada, except for
            applications for injunctive relief by SpendTheBits which
            SpendTheBits may bring in any jurisdiction. You hereby irrevocably
            submit to the jurisdiction and venue of such court in any such
            action or proceeding.
          </li>
          <li>
            <strong>Waiver of Jury Trial.</strong> TO THE EXTENT PERMITTED BY
            LAW, AND SUBJECT TO THE MANDATORY ARBITRATION PROVISION, YOU
            ACKNOWLEDGE, AGREE AND UNDERSTAND THAT, WITH RESPECT TO ANY DISPUTE
            WITH SPENDTHEBITS, OR ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES
            OR AGENTS, ARISING OUT OF OR RELATING TO THIS AGREEMENT: YOU HEREBY
            GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY.
          </li>
          <li>
            <strong>Waiver of Class Action Rights.</strong> TO THE EXTENT
            PERMITTED BY LAW, AND SUBJECT TO THE MANDATORY ARBITRATION
            PROVISION, YOU ACKNOWLEDGE, AGREE AND UNDERSTAND THAT, WITH RESPECT
            TO ANY DISPUTE WITH SPENDTHEBITS, OR ITS RESPECTIVE OFFICERS,
            DIRECTORS, EMPLOYEES OR AGENTS, ARISING OUT OF OR RELATING TO THIS
            AGREEMENT, YOU HEREBY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER
            OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT INCLUDING BUT NOT LIMITED TO
            CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
          </li>
          <li>
            <strong>Notices.</strong> Any notice or other communication required
            or permitted under this Agreement and intended to have legal effect
            must be given in writing to the other party. You may send notices to
            5911 56 Ave NW, Edmonton, AB T6B 3E2 and{" "}
            <a href="mailto:security@SpendTheBits.com">
              security@SpendTheBits.com
            </a>{" "}
            and SpendTheBits may send any notice to You at the Email Address.
            Notices will be deemed to have been given upon the next full
            business day in the Province of Alberta after being sent.
          </li>
          <li>
            <p>
              <strong>Severability and Waiver.</strong> In the event that any
              provision of this Agreement is held to be invalid or
              unenforceable, the valid or enforceable portion thereof and the
              remaining provisions of this Agreement will remain in full force
              and effect. Any waiver or failure to enforce any provision of this
              Agreement on one occasion will not be deemed a waiver of any other
              provision or of such provision on any other occasion. All waivers
              must be in writing.
            </p>
            <p>
              Other than as expressly stated herein, the remedies provided
              herein are in addition to, and not exclusive of, any other
              remedies of a party at law or in equity.
            </p>
          </li>
          <li>
            <strong>Enurement.</strong> All covenants, representatives,
            warranties and agreements of the parties contained herein will be
            binding upon and will enure to the benefit of the parties and their
            respective successors, permitted assigns, legal representatives,
            heirs and trustees.
          </li>
          <li>
            <strong>Questions or Additional Comments.</strong> If You have
            questions regarding this Agreement or wish to obtain additional
            information, please send an e-mail to{" "}
            <a href="mailto:security@SpendTheBits.com">
              security@SpendTheBits.com
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsCondition;
