import React from "react";

const SolidWrapper = (props) => {
  const wrapperStyle = {
    borderRadius: "7.2rem",
    background: "var(--bgColor)",
    padding: "6rem",
  };
  return (
    <div
      className={props.className || null}
      style={{ ...wrapperStyle, ...props.style }}
    >
      {props.children}
    </div>
  );
};

export default SolidWrapper;
