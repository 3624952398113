import styles from "./style.module.scss";

const CashTermsCondition = () => {
  return (
    <div className={`containerWidth ${styles.privacyPolicy}`}>
      <div>
        <h1>Terms & Condition</h1>
        <p>Last modified: 19 MARCH, 2024</p>
        <h4>SPENDTHEBITS INC. USER AGREEMENT</h4>
        <p>
          This agreement is made between SpendTheBits Inc. (“SpendTheBits”), and
          you (“You”, “Your” and other similar words). Your access to and use of
          the SpendTheBits Wallet and STB CASH APP, the latest product offering
          by SpendTheBits designed for FIAT Payments, is governed solely by the
          terms of this Agreement which supersedes and replaces the terms of any
          other prior agreement, writing, or understanding between You and
          SpendTheBits. “You” includes all your heirs, trustees, administrators,
          and other legal representatives.
        </p>
        <p>
          SpendTheBits carries on the business of providing users the ability to
          send bitcoin or other Virtual Currency (as defined below) onto the
          mobile wallet application (the “App”), and use the Wallet (as defined
          below) located in the App to store their Virtual Currency, to send
          their Virtual Currency to friends and family, and to buy goods and
          services with their Virtual Currency. In addition, SpendTheBits
          introduces STB CASH, a Non-Blockchain, centralized versatile Canadian
          fiat payment platform tailored to redefine the convenience of digital
          payments combined with the robustness of traditional banking, minus
          the monthly fees. STB CASH allows users to make person-to-person (P2P)
          payments, person-to-business (P2B) transactions, and bill payments,
          and integrates with Interac for seamless deposits and withdrawals,
          including options for EFT and wire transactions, all while enjoying
          cheaper and faster transactions as a result of innovative payment
          solutions offered by SpendTheBits.
        </p>
        <p>
          SpendTheBits reserves the right to modify the terms and conditions of
          this Agreement or its policies relating to the Software, the
          SpendTheBits Wallet, and STB CASH at any time, effective upon posting
          of an updated version of this Agreement on our platform. Your
          continued use of the SpendTheBits Solution and STB CASH following
          notice of such changes means that you agree to and accept the
          Agreement as amended. If you do not agree to any modification of this
          Agreement, you must immediately stop using the Software, the
          SpendTheBits Solution, STB CASH, and notify us that you are
          terminating this Agreement.
        </p>
        <p>
          BY USING THE SOFTWARE AND STB CASH, YOU ACKNOWLEDGE AND AGREE THAT YOU
          HAVE READ AND AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF
          THIS AGREEMENT (WHICH ALSO INCLUDES THE SPENDTHEBITS USER PRIVACY
          POLICY)
        </p>
        <h4>TERMS AND CONDITIONS</h4>
        <p>1. DEFINITIONS. As used in this Agreement</p>
        <ul>
          <li>
            “Agreement” means these Terms and Conditions, the SpendTheBits
            Solution User Privacy Policy, and any other document incorporated by
            reference into this Agreement.
          </li>
          <li>
            “Applicable Laws” means all laws, regulations, and codes applicable
            to SpendTheBits and You, including those governing digital payments,
            privacy, and financial services.
          </li>
          <li>
            “Confidential Information” means all information regarding
            SpendTheBits’s business, including, without limitation, the
            SpendTheBits Solution, the Wallet, STB CASH App, the App, and any
            software, as well as other confidential or proprietary information
            disclosed under this Agreement.
          </li>
          <li>
            “Email Address” means a single valid email address provided by You.
          </li>
          <li>
            “Fork” means a change to the underlying protocol of a Virtual
            Currency network that results in more than one version of a Virtual
            Currency, the result of which may be one or more versions that are
            not supported by SpendTheBits.
          </li>
          <li>
            “Personal Information” means information about an identifiable
            individual, including You, that is collected, used, and disclosed in
            the course of delivering the SpendTheBits Solution including STB
            CASH.
          </li>
          <li>
            “Services” means the services provided by SpendTheBits to You,
            including the functionalities of the SpendTheBits Wallet and STB
            CASH app wallet.
          </li>
          <li>
            {" "}
            “Software” means the online software used to provide the
            SpendTheBits Solution, STB CASH, and Wallet, and delivered by
            SPENDTHEBITS to You, as made available by SpendTheBits from time to
            time.
          </li>
          <li>
            “SpendTheBits User Privacy Policy” means the policy governing the
            collection, use, disclosure, and retention of information of an
            identifiable individual. You can access here:
            https://spendthebits.com/privacy-policy
          </li>
          <li>
            {" "}
            “SpendTheBits Parties” means SpendTheBits and its officers,
            directors, agents, subsidiaries, suppliers, and employees.
          </li>
          <li> “User” means any user of the Software, including You.</li>
          <li>
            {" "}
            “User System” means the technology, including hardware and software,
            used by You to receive or access the Software.
          </li>
          <li>
            {" "}
            “Virtual Currency” and “Virtual Currencies” means a cryptocurrency,
            digital currency, digital asset, cryptoasset, or other such similar
            term describing, for example, Bitcoin, XRP or Ether but does not
            include a derivative of a virtual currency, or a security, as
            defined under applicable law.
          </li>
          <li>
            {" "}
            “Wallet” means the wallet software published by SpendTheBits Inc.,
            consisting of software that permits you to self-custody Virtual
            Currency, organize network addresses, view transaction history, and
            transact in Virtual Currencies OR “STB CASH App Wallet” means the
            innovative Canadian fiat payment platform provided by SpendTheBits,
            designed to facilitate personal and business transactions, including
            P2P payments, P2B transactions, and bill payments, integrating
            seamlessly with Interac and offering additional deposit and
            withdrawal options such as EFT and wire transactions as contemplated
            in this Agreement.
          </li>
          <li>
            {" "}
            “Your Data” means any data, information, or information submitted by
            You and includes, without limitation, Personal Information.
          </li>
          <li>
            {" "}
            “STB CASH” means the innovative Canadian fiat payment platform
            provided by SpendTheBits, designed to facilitate personal and
            business transactions, including P2P payments, P2B transactions, and
            bill payments, integrating seamlessly with Interac and offering
            additional deposit and withdrawal options such as EFT and wire
            transactions.
          </li>
        </ul>
        <p>
          With the introduction of STB CASH, a versatile Canadian fiat payment
          platform designed to streamline how Canadians transact daily, our
          commitment to your privacy extends to all interactions and
          transactions made through this new feature. STB CASH merges the
          convenience of digital payments with the robustness of traditional
          banking, minus the monthly fees, and integrates with Interac for
          effortless deposits and withdrawals, including options for EFT and
          wire transactions.
        </p>
        <p>
          <b>2. Software</b>
        </p>
        <p>
          2.1 License to the Software. Subject to You complying with each term
          of this Agreement, SpendTheBits hereby grants to You a limited,
          revocable, personal, non-sub-licensable, non-transferable,
          non-exclusive right to access and use the Software to access the
          SpendTheBits Solution, including STB CASH, in accordance with this
          Agreement solely for personal reasons and not for resale or to provide
          services to third parties.
        </p>
        <p>
          2.2 No Sharing. Your access to the Software cannot be shared with
          anyone. You will maintain a single user account represented by Your
          Email Address on the App. You will only use the Software and access
          the SpendTheBits Solution, including STB CASH, under this single
          identity and will not share Your password or PIN with any other person
          or permit another person to interact with the Software or STB CASH
          using Your account information, PIN, or password.
        </p>
        <p>
          2.3 Maintenance and Availability. SpendTheBits shall use commercially
          reasonable efforts to (a) maintain the security of the Software,
          including STB CASH; and (b) make the Software available in accordance
          with this Agreement. The Software, including STB CASH, will be
          unavailable from time to time for regularly scheduled and other
          maintenance and updates. SpendTheBits does not guarantee any uptime,
          speed, or availability of the Software or the SpendTheBits Solution,
          including STB CASH, to You and specifically denies any responsibility
          or liability for any loss, damages, or claim arising out of, or
          related to, any unavailability or speed of the software (or part
          thereof) at any time. SpendTheBits may change, suspend, or discontinue
          any aspect of the SpendTheBits Solution or Software, including STB
          CASH, at any time without notice or liability.
        </p>
        <p>3. YOUR USE OF THE SOFTWARE</p>
        <p>
          3.1 Access and Security Guidelines. You will use Your real name and
          Your own valid Email Address and create Your own 4-digit personal
          identification number (PIN) and password to use in association with
          Your Email Address for access to and use of the Software and the
          SpendTheBits Solution, including STB CASH. The SpendTheBits Solution
          automatically enables two-factor authentication by default. Once the
          onboarding process is complete and the PIN is finalized, You will have
          the option of logging in either via the PIN or biometric
          authentication, which is automatically enabled upon successful
          onboarding. We do not store any biometric information on our servers;
          that information is entirely stored on Your User System. You are
          solely responsible for ensuring the security and confidentiality of
          Your PIN and shall not share your PIN with anyone at any time. You
          will prevent unauthorized access to or use of the Software or the
          SpendTheBits Solution, including STB CASH, and notify SpendTheBits
          promptly of any such unauthorized use.
        </p>
        <p>
          3.2 Your Data. You are solely responsible for Your Data that you
          provide, and You will not provide, post, or transmit any data,
          information, content, or material that: (a) infringes,
          misappropriates, or violates any intellectual property rights,
          publicity/privacy rights, or other rights of any third party, or any
          law or regulation; or (b) contains any viruses or programming routines
          intended to damage, surreptitiously intercept, or expropriate any
          system, data, or personal information. SpendTheBits may take any
          remedial action if Your Data violates this Section; however,
          SpendTheBits is under no obligation to review any data, information,
          content, or material on the SpendTheBits Solution, including STB CASH,
          for accuracy or potential liability.
        </p>
        <p>
          3.3 Fees. In consideration of Your use of the SpendTheBits Solution,
          including STB CASH, You agree to pay any and all fees as described in
          the App and Our website, which fees shall be updated from time to time
          by Us at our discretion, and published on the App and Our website.
        </p>
        <p>
          3.4 Use Restrictions. You are responsible for all activities that
          occur under Your account. In using the Software, accessing the
          SpendTheBits Solution, including STB CASH, you will not, and will not
          attempt to, engage in any of the following actions:
        </p>
        <ul>
          <li>
            Reverse engineer, disassemble, or decompile any component of the
            Software, the SpendTheBits Solution, or STB CASH.
          </li>
          <li>
            Interfere in any manner with the operation of the Software, the
            SpendTheBits Solution, or STB CASH, or the hardware and network used
            to operate them.
          </li>
          <li>
            Transfer any of your rights under this Agreement, use the Software,
            or access the SpendTheBits Solution or STB CASH for the benefit of a
            third party or operate a service bureau.
          </li>
          <li>
            {" "}
            Modify, copy, or make derivative works based on any part of the
            Software, the SpendTheBits Solution, or STB CASH.
          </li>
          <li>
            Use the Software or access the SpendTheBits Solution or STB CASH in
            any manner that exceeds the scope of use permitted under Section 2.1
            of this Agreement.
          </li>
          <li>
            Engage in any unlawful, libelous, defamatory, obscene, pornographic,
            indecent, lewd, suggestive, harassing, threatening, invasive of
            privacy or publicity rights, abusive, inflammatory, fraudulent, or
            otherwise objectionable use of the Software or SpendTheBits
            Solution, including STB CASH.
          </li>
          <li>
            Use the Software, the SpendTheBits Solution, or STB CASH in a way
            that would constitute, encourage, or provide instructions for a
            criminal offense, violate the rights of any party, or otherwise
            create liability or violate any local, provincial, national, or
            international law.
          </li>
          <li>
            Infringe on any patent, trademark, trade secret, copyright, or other
            intellectual or proprietary rights of any party anywhere in the
            world through your use of the Software, the SpendTheBits Solution,
            or STB CASH.
          </li>
          <li>
            Disclose personal information about another person without
            authorization or impersonate any person or entity, thereby
            misrepresenting your affiliation with a person or entity.
          </li>
          <li>
            Use the Software, the SpendTheBits Solution, or STB CASH for
            unsolicited promotions, political campaigning, advertising,
            solicitations, or to spread viruses, corrupted data, or other
            harmful, disruptive, or destructive files.
          </li>
          <li>
            Engage in any activity that, in the sole judgment of SpendTheBits,
            is objectionable or which restricts or inhibits any other person
            from using or enjoying the SpendTheBits Solution, including STB
            CASH, or which may expose SpendTheBits or its users to harm or
            liability of any type.
          </li>
        </ul>
        <p>
          3.5 No Competitive Use. You may not subscribe for, use, or access the
          Software, including STB CASH, if you are a competitor of SpendTheBits,
          except with SpendTheBits’s prior written consent. Furthermore, you may
          not use the Software for monitoring its performance or functionality,
          or for any benchmarking or competitive purposes.
        </p>
        <p>
          3.6 Suspension or Cancellation of Your Use. SpendTheBits reserves the
          right to terminate or suspend your access to the Software, the
          SpendTheBits Solution, and STB CASH, either partially or completely,
          without prior notice for any reason. SpendTheBits will confirm such
          termination or suspension by subsequent notice. You are responsible
          for any claims, fees, fines, penalties, and other liabilities incurred
          by us or others caused by your use of the SpendTheBits Solution,
          including STB CASH.
        </p>
        <p>
          3.7 Consent and License You Give Us. By using the SpendTheBits
          Solution, including STB CASH, you grant to SpendTheBits the consent to
          collect, use, store, and disclose your Personal Information in
          accordance with the SpendTheBits Solution User Privacy Policy.
        </p>
        <p>
          3.8 Content. SpendTheBits does not vouch for the accuracy,
          reliability, or truthfulness of any content posted, stored, or
          uploaded by you or any third party on the SpendTheBits Solution,
          including STB CASH. You bear all risks associated with exposure to
          such content, and SpendTheBits assumes no liability for any loss or
          damage arising from such content.
        </p>
        <p>4. THE WALLET</p>
        <ul>
          <li>
            Supported Fiat/Virtual Currencies: Do not attempt to store
            Fiat/Virtual Currencies in Your Wallet that are not supported by the
            Wallet, including any fiat currencies not facilitated by STB CASH.
          </li>
          <li>
            {" "}
            Key Generation and Backup: When You create a SpendTheBits Wallet,
            the Software generates a cryptographic private and public key pair
            that you may use to send and receive any supported Virtual Currency
            via the relevant Virtual Currency network. YOU MUST STORE, OUTSIDE
            OF THE SERVICES, A BACKUP OF ALL WALLET CREDENTIALS, INCLUDING YOUR
            PASSPHRASES, IDENTIFIERS, BACKUP PHRASES, PRIVATE KEYS AND NETWORK
            ADDRESSES. If you do not maintain a backup of your Wallet data
            outside of the Services, you will not be able to access Virtual
            Currency previously accessed using your Wallet in the event that we
            discontinue or no longer offer some or all of the Services or may
            otherwise lose access to Virtual Currency. Also, in the event you
            delete the App and reinstall it and you lose your backup data, you
            will lose your Virtual Currency forever. We are not responsible for
            maintaining this data on your behalf. Upon creating an STB CASH App
            Wallet, the Software does not generate any cryptographic private and
            public key pair. For the STB CASH App and transactions, different
            secure mechanisms are employed to ensure transaction security. YOU
            MUST STORE, OUTSIDE OF THE SERVICES, A BACKUP OF ALL WALLET AND STB
            CASH CREDENTIALS, including username, password, identifiers, backup
            password phrases, and network addresses if applicable. Failure to
            maintain such a backup may result in the inability to access fiat
            funds within STB CASH in the event of data loss or discontinuation
            of the STB CASH app service. In the event of discontinuation of the
            STB CASH app service, the SpendTheBits team will notify customers at
            least 30 Day’s notice to withdraw funds from the platform.
          </li>
          <li>
            {" "}
            Risk of Loss: You bear all risks associated with the loss of Virtual
            Currency and fiat funds within the SpendTheBits or STB CASH app.
            SpendTheBits is not liable for fluctuations in the fiat or Virtual
            Currency value of your assets held in the Wallet or STB CASH.
          </li>
          <li>
            User Control and Withdrawal: You control the assets held in your
            Wallet and STB CASH account. Subject to system outages and downtime,
            you may withdraw or transfer your Virtual Currency or fiat funds
            according to applicable policies and network capabilities.
          </li>
          <li>
            {" "}
            Transaction Confirmation: Virtual Currency transactions require
            confirmation and recording on the respective Virtual Currency
            network's ledger, which is a decentralized, peer-to-peer network not
            controlled by SpendTheBits. Transactions involving STB CASH will be
            processed in accordance with SpendTheBits's operational procedures
            and financial regulations.
          </li>
          <li>
            {" "}
            Network Dependency: SpendTheBits has no control over any Virtual
            Currency network or the processing of transactions outside the scope
            of STB CASH, and cannot guarantee the completion or timely
            processing of transactions on these networks.
          </li>
          <li>
            {" "}
            Immutability of Transactions: Once submitted to a Virtual Currency
            network, transaction details cannot be modified or canceled by
            SpendTheBits. This immutability does not apply to STB CASH
            transactions, which are governed by separate operational rules.
          </li>
          <li>
            {" "}
            Forks and Network Disruptions: In the event of a Fork or network
            disruption, SpendTheBits may not support related Virtual Currency
            activity. STB CASH operations may also be affected by system outages
            or banking disruptions, for which SpendTheBits disclaims liability
            for any resulting loss.
          </li>
          <li>
            {" "}
            Security and Access: SpendTheBits does not store or have access to
            your PIN, keys, network addresses, or transaction history for both
            the SpendTheBits Wallet and STB CASH APP Wallet. You are responsible
            for securing and remembering your PIN and all access credentials.
          </li>
          <li>
            {" "}
            Wallet Creation and Security Practices: When creating a SpendTheBits
            Wallet or STB CASH account, you must: (a) create a strong password
            that you do not use for any other website or online service; (b)
            provide accurate and truthful information; (c) protect and keep
            secret all credentials for the Wallet; (d) protect and keep secret
            your PIN; (e) protect access to your device and your Wallet; (f)
            promptly notify us if you discover or otherwise suspect any security
            breaches related to your Wallet; and (g) use the backup
            functionality provided through the Wallet and safeguard your backup
            files. You agree to take responsibility for all activities that
            occur with your Wallet and accept all risks of any authorised or
            unauthorized access to your Wallet, to the maximum extent permitted
            by law.
          </li>
        </ul>
        <p>5. YOUR WARRANTIES AND PROMISES TO SPENDTHEBITS</p>
        <p>
          5.1 Your Warranties. You hereby represent and warrant to SpendTheBits,
          now and at all times during your access and use of the Software, the
          SpendTheBits Solution, and STB CASH, and SpendTheBits is relying on
          your representations and warranties in allowing you to use and access
          the Software, the SpendTheBits Solution, including STB CASH, that:
        </p>
        <ul>
          <li>
            {" "}
            You are a resident of a jurisdiction in which the Software, the
            SpendTheBits Solution, and STB CASH, and your use of the Software,
            the SpendTheBits Solution, and STB CASH is not contrary to
            Applicable Laws
          </li>{" "}
          <li>
            {" "}
            Any information that you provide at any time to SpendTheBits,
            including but not limited to information related to STB CASH
            transactions, is true, accurate, and complete;
          </li>{" "}
          <li>
            {" "}
            You have all necessary rights to provide any of Your Data, or any
            other information, data, materials, or content to SpendTheBits for
            the purposes described in this Agreement, including those related to
            STB CASH
          </li>{" "}
          <li>
            {" "}
            You agree to hold SpendTheBits’s Confidential Information, including
            details related to STB CASH functionalities and security measures,
            in strict confidence and not to use such Confidential Information
            for any purpose except as expressly permitted hereunder.
          </li>
        </ul>
        <p>
          5.2 Security Vulnerability. In order to maintain the Software, the
          SpendTheBits Solution, and STB CASH as secure as possible, you must
          not disclose any security vulnerability to any person except
          SpendTheBits. Immediately upon discovering any issue or problem that
          may pose a risk to the security or use of the SpendTheBits Solution,
          STB CASH, or to Your Data (or any data of any other user of the
          Software, including STB CASH), you must notify us by contacting
          security@SpendTheBits.com
        </p>
        <p>6. OWNERSHIP</p>
        <p>
          6.1 SpendTheBits Solution and Technology. SpendTheBits exclusively
          owns and retains all rights, title, and interest in and to the
          Software, the Wallet, STB CASH, and the SpendTheBits Solution, along
          with all software, materials, formats, interfaces, information,
          designs, data, graphics, images, video, logos, icons, audio, content,
          computer code, SpendTheBits’s Confidential Information, and
          SpendTheBits proprietary information and technology used by
          SpendTheBits or provided to You in connection with the SpendTheBits
          Solution, STB CASH, and any compilation thereof (“SpendTheBits
          Technology”). “SpendTheBits Inc.,” the SpendTheBits logo, and other
          SpendTheBits logos and product and service names are or may be
          trademarks of SpendTheBits (the “SpendTheBits Marks”). Without our
          prior written permission, and except as solely enabled by any link as
          provided by us, you agree not to display or use in any manner the
          SpendTheBits Marks, registered or not. All copyright, trademarks,
          service marks, and other proprietary notices contained in the original
          SpendTheBits Technology must be preserved on any copy you make of the
          SpendTheBits Technology. The SpendTheBits Technology is protected by
          intellectual property rights under the laws of Canada and other
          countries, including copyright and trademark laws, and is owned by or
          licensed to SpendTheBits. Other than as expressly licensed to You in
          this Agreement, no license or other rights in the SpendTheBits
          Technology are granted to You, and all such rights are hereby
          expressly reserved by SpendTheBits. SpendTheBits shall have a
          royalty-free, worldwide, transferable, sublicenseable, irrevocable,
          perpetual license to use or incorporate into the Software, the Wallet,
          and STB CASH any suggestions, enhancement requests, recommendations,
          or other feedback provided by You, relating to the Software, the
          Wallet, or STB CASH platform.
        </p>
        <p>
          6.2 Your Data. You retain all ownership rights in and to Your Data,
          including data that constitutes Personal Information. You grant to
          SpendTheBits all necessary licenses in and to Your Data as required
          for SpendTheBits to operate the SpendTheBits Solution including STB
          CASH platform, comply with applicable laws, provide support and
          maintenance, and enhance the Software, the Wallet, and STB CASH. This
          includes the use, reproduction, modification, adaptation, publication,
          distribution, and display of Your Data in connection with the
          operation of the SpendTheBits Solution and STB CASH. SpendTheBits
          commits to protecting Your Data in accordance with the SpendTheBits
          User Privacy Policy and applicable data protection laws.
        </p>
        <p>7. TERM AND TERMINATION</p>
        <p>
          7.1 Term. The term of this Agreement commences on the date you sign up
          for the Software, begin to use the App, the SpendTheBits Solution, or
          STB CASH platform, and continues until terminated in accordance with
          the provisions of this Agreement.
        </p>
        <p>7.2 Termination.</p>
        <ul>
          <li>
            {" "}
            User Initiated: You may cease using the Software, the SpendTheBits
            Solution, and STB CASH platform at any time.
          </li>
          <li>
            {" "}
            SpendTheBits Initiated: SpendTheBits may cease providing You the
            Software, the SpendTheBits Solution, or STB CASH (or any portion
            thereof) at any time without notice. Additionally, SpendTheBits may
            terminate this Agreement immediately and without notice if You
            violate any of your obligations under this Agreement.
          </li>
          <li>
            Upon Termination: Upon the termination of this Agreement for any
            reason:
            <ul>
              <li>
                {" "}
                The license granted to SpendTheBits in respect of Your Data
                survives for so long as SpendTheBits is required by law to
                retain such information.
              </li>
              <li>
                {" "}
                SpendTheBits will cease providing and You will cease using the
                Software, the SpendTheBits Solution, and STB CASH.
              </li>
              <li>
                {" "}
                SpendTheBits will be entitled to retain and use Your Data in
                accordance with the terms of this Agreement, subject always to
                the laws of the Province of Alberta and applicable laws of
                Canada therein. The rights and duties of the parties under
                Sections 3.7, 4, 6, 7.2, 8, 9, 10, and 11 will survive the
                termination or expiration of this Agreement.
              </li>
            </ul>
          </li>
        </ul>

        <p>
          7.3 Survival: The rights and obligations of the parties under Sections
          relating to Ownership, Your Data, Confidentiality, Limitation of
          Liability, and any other provisions which by their nature should
          survive, will continue after the termination or expiration of this
          Agreement.
        </p>
        <p>8. WARRANTY DISCLAIMER</p>
        <p>
          8.1 Warranty Disclaimed. SpendTheBits expressly disclaims all
          warranties related to the SpendTheBits Solution, STB CASH, the
          Software, and any services or content provided through the Software.
          THE SOFTWARE, THE SPENDTHEBITS SOLUTION, STB CASH, AND ALL DATA,
          MATERIALS, AND CONTENT PROVIDED IN CONNECTION WITH THIS AGREEMENT BY
          SPENDTHEBITS AND ITS SUPPLIERS ARE DELIVERED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
          IMPLIED. SPENDTHEBITS AND ITS SUPPLIERS DISCLAIM ALL WARRANTIES,
          INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SPENDTHEBITS
          DOES NOT WARRANT THAT THE SERVICES, INCLUDING STB CASH, WILL BE
          ERROR-FREE, ACCURATE, RELIABLE, UNINTERRUPTED, OR THAT ANY DEFECTS
          WILL BE CORRECTED. SPENDTHEBITS IS NOT RESPONSIBLE FOR THE CONTENTS OF
          ANY LINKED SITE OR ANY LINK CONTAINED IN A LINKED SITE, OR ANY CHANGES
          OR UPDATES TO SUCH SITES.
        </p>
        <p>
          8.2 Third-Party Links. The Software may allow you to link to other
          websites or resources on the Internet, and other websites or resources
          may contain links to the SpendTheBits’s websites. SPENDTHEBITS IS NOT
          RESPONSIBLE for the content, availability, or accuracy of these
          external sites and resources. The inclusion of any link does not imply
          endorsement by SpendTheBits. You acknowledge that SPENDTHEBITS SHALL
          NOT BE LIABLE for any damage or loss caused by your use of or reliance
          on any external site or resource.
        </p>
        <p>9. INDEMNITY</p>
        <p>
          9.1 Indemnification by You. You agree to indemnify, defend, and hold
          harmless the SpendTheBits Parties against any claims, actions, or
          demands resulting from (a) your use of the SpendTheBits Solution, STB
          CASH, the App, the Wallet, or the Software, including any violation of
          laws, regulations, or orders; or (b) allegations that Your Data, or
          its use under this Agreement, infringes or misappropriates third-party
          intellectual property rights or causes harm to a third party. This
          indemnification covers all associated damages, fees, fines, and costs.
          You are not obligated to indemnify SpendTheBits Parties if the claim
          or action arises solely from SpendTheBits's material breach of this
          Agreement.
        </p>
        <p>
          9.2 Conditions for Settlement. Any settlement or compromise of a claim
          requiring indemnification under this section must meet the following
          conditions without SpendTheBits’s prior approval: (A) no admission of
          breach by SpendTheBits, (B) no effect on SpendTheBits’s defenses, and
          (C) no imposition of any obligations or conditions on SpendTheBits.
          SpendTheBits will cooperate with your defense of such claim, provided
          your actions do not conflict with SpendTheBits's interests.
        </p>
        <p>10. LIMITATION OF LIABILITY</p>
        <p>
          10.1 Limitation on Total Cumulative Liability. SPENDTHEBITS's total
          cumulative liability to you, for any and all claims arising from or in
          connection with this Agreement, whether in contract, tort, or under
          any other theory of liability, related to the Software, the
          SpendTheBits Solution, including STB CASH, will not exceed the
          amounts, if any, actually paid by you to SPENDTHEBITS during the
          six-month period immediately preceding the formal written notice of
          the claim. This limitation applies collectively to all your claims,
          actions, and proceedings against SPENDTHEBITS and will not be
          increased by the existence of more than one incident or claim.
        </p>
        <p>
          10.2 Exclusion of Certain Damages. Under no circumstances will
          SPENDTHEBITS be liable for any special, incidental, indirect, or
          consequential damages arising out of or in connection with this
          Agreement, the Software, the SpendTheBits Solution, or STB CASH,
          including but not limited to interrupted communications, lost data,
          lost profits, or damages resulting from inconvenience, delay, or loss
          of use of any information or data or the services provided herein,
          even if SPENDTHEBITS has been advised of the possibility of such
          damages. This exclusion applies regardless of the legal theory under
          which such damages are sought and notwithstanding the failure of the
          essential purpose of any limited remedy provided in this Agreement.
        </p>
        <p>11. GENERAL PROVISIONS</p>
        <ul>
          <li>
            {" "}
            Express Consent. By entering into this Agreement, You expressly
            consent to receiving electronic messages (emails, text messages,
            instant messages or any similar form of message) from SpendTheBits
            which messages shall provide information related to the SpendTheBits
            Solution including the STB CASH app. If You wish to use the
            SpendTheBits Solution on a mobile device, You further expressly
            consent to the installation of software (and any updates) required
            to use the SpendTheBits Solution as an App, including the
            installation of the Wallet, on mobile devices. You may contact
            SpendTheBits at the address, phone number and email located below in
            order to cancel Your consent to receive electronic messages and/or
            software updates at any time.
          </li>
          <li>
            {" "}
            Publicity. SpendTheBits may make public announcements, including but
            not limited to, press releases and media announcements, of the
            existence of this Agreement, the SpendTheBits Solution including the
            STB CASH app and the Software.
          </li>
          <li>
            {" "}
            Assignment. You may not assign any rights or obligations arising
            under this Agreement, whether by operation or law or otherwise,
            without the prior written consent of SpendTheBits. You agree that
            SpendTheBits may subcontract certain aspects of the SpendTheBits
            Solution including the STB CASH app to qualified third parties,
            provided that any such subcontracting arrangement will not relieve
            SpendTheBits of any of its obligations hereunder. Subject to the
            foregoing limitation, this Agreement shall inure to the benefit of
            and shall be binding on the heirs, legal representatives, successors
            and assignees of the parties.
          </li>
          <li>
            {" "}
            Disputes. You agree that any action at law or in equity arising out
            of or relating to this Agreement shall be filed only in the
            provincial and federal courts located in Edmonton, Alberta and you
            hereby irrevocably and unconditionally consent and submit to the
            exclusive jurisdiction of such courts over any suit, action or
            proceeding arising out of this Agreement or Your use of the
            Software, the App and SpendTheBits Solution. To the extent permitted
            by law, You agree that You will not bring a claim under this
            Agreement more than 12 months from when Your claim first arises.
          </li>
          <li>
            {" "}
            Injunctions. Notwithstanding the foregoing, if you breach or
            threaten to breach any provision of this Agreement You acknowledge
            that irreparable harm will result to SpendTheBits if You breach any
            obligation under this Agreement and You acknowledge and agree that
            such a breach would not be properly compensable by an award of
            damages. Accordingly, You agree that SpendTheBits shall be entitled
            to, in addition to other available remedies and damages, injunctive
            relief or other equitable relief enjoining such breach at the
            earliest possible date.
          </li>
          <li>
            {" "}
            Governing Law and Venue. This Agreement will be governed by and
            construed in accordance with the laws of the Province of Alberta,
            Canada without giving effect to conflict of laws principles. Subject
            to the mandatory arbitration provisions, any action or proceeding
            arising from or relating to this Agreement must be brought in a
            court sitting in Alberta, Canada, except for applications for
            injunctive relief by SpendTheBits which SpendTheBits may bring in
            any jurisdiction. You hereby irrevocably submit to the jurisdiction
            and venue of such court in any such action or proceeding.
          </li>
          <li>
            {" "}
            Waiver of Jury Trial. TO THE EXTENT PERMITTED BY LAW, AND SUBJECT TO
            THE MANDATORY ARBITRATION PROVISION, YOU ACKNOWLEDGE, AGREE AND
            UNDERSTAND THAT, WITH RESPECT TO ANY DISPUTE WITH SPENDTHEBITS, OR
            ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS, ARISING OUT
            OF OR RELATING TO THIS AGREEMENT: YOU HEREBY GIVE UP YOUR RIGHT TO
            HAVE A TRIAL BY JURY.
          </li>
          <li>
            {" "}
            Waiver of Class Action Rights. TO THE EXTENT PERMITTED BY LAW, AND
            SUBJECT TO THE MANDATORY ARBITRATION PROVISION, YOU ACKNOWLEDGE,
            AGREE AND UNDERSTAND THAT, WITH RESPECT TO ANY DISPUTE WITH
            SPENDTHEBITS, OR ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES OR
            AGENTS, ARISING OUT OF OR RELATING TO THIS AGREEMENT, YOU HEREBY
            GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF
            CLAIMANTS, IN ANY LAWSUIT INCLUDING BUT NOT LIMITED TO CLASS ACTION
            LAWSUITS INVOLVING ANY SUCH DISPUTE.
          </li>
          <li>
            {" "}
            Notices. Any notice or other communication required or permitted
            under this Agreement and intended to have legal effect must be given
            in writing to the other party. You may send notices to 28-655
            Tamarack Rd NW, Edmonton, Alberta, Canada, T6T0N4 OR
            security@SpendTheBits.com and SpendTheBits may send any notice to
            You at the Email Address. Notices will be deemed to have been given
            upon the next full business day in the Province of Alberta after
            being sent.
          </li>
          <li>
            {" "}
            Severability and Waiver. In the event that any provision of this
            Agreement is held to be invalid or unenforceable, the valid or
            enforceable portion thereof and the remaining provisions of this
            Agreement will remain in full force and effect. Any waiver or
            failure to enforce any provision of this Agreement on one occasion
            will not be deemed a waiver of any other provision or of such
            provision on any other occasion. All waivers must be in writing.
            Other than as expressly stated herein, the remedies provided herein
            are in addition to, and not exclusive of, any other remedies of a
            party at law or in equity.
          </li>
          <li>
            {" "}
            Enurement. All covenants, representatives, warranties and agreements
            of the parties contained herein will be binding upon and will enure
            to the benefit of the parties and their respective successors,
            permitted assigns, legal representatives, heirs and trustees.
          </li>
          <li>
            {" "}
            Questions or Additional Comments. If You have questions regarding
            this Agreement or wish to obtain additional information, please send
            an e-mail to security@SpendTheBits.com
          </li>
        </ul>
        <p>
          SpendTheBits including the STB CASH app isn’t storing any personal
          information/biometric info (auto-enabled, only stored in your phone).
        </p>
      </div>
    </div>
  );
};

export default CashTermsCondition;
