import React, { useEffect } from 'react';
import { Banner, About, Products, Commitment } from './pageComponents';

const Home = () => {

  useEffect(() => {
    const hash = window.location.hash.slice(1); // Remove the '#' character from the hash
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.scrollTo(0,0)
      }
    }
  }, []);

  // const productRef = useRef();
  // const location= useLocation();

  // useEffect(() => {
  //   if(location.state) {
  //     console.log(location.state);
  //   }
  // }, [])

  return (
    <>
    <Banner />
    <About />
    <Products/>
    <Commitment />
    </>
  )
}

export default Home